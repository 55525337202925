import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";
import Grid from "@mui/material/Grid";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Typography, Container, Button, FormControl, InputLabel, MenuItem, Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import {VISA_URL} from "../../constant/constants";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const ApplyUniversity = forwardRef((props, ref) => {
    // let userData = useSelector((state) => state?.saveLogin);
    const userData = JSON.parse(localStorage.getItem("userData"));
    React.useEffect(() => {
    }, []);
    useImperativeHandle(ref, () => ({
        showAlert(data) {
            console.log(data)
            setApplications(data);
            //getApplications(id)
        },
    }));
// export const ApplyUniversity = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openApply, setOpenApply] = React.useState(false)
    const [universityList, setUniversityList] = React.useState([]);
    const [university, setUniversity] = React.useState("");
    const [selectedUniversity, setSelectedUniversity] = React.useState("");

    const [universityName, setUniversityName] = React.useState([]);
    const [appIntake, setAppIntake] = React.useState('');
    const [applications, setApplications] = React.useState([]);
    const [countryList, setCountryList] = React.useState([]);
    const [country, setCountry] = React.useState('');
    const [showUniversity, setShowUniversity] = React.useState(false);
    const [countryName, setCountryName] = React.useState('');
    const token = JSON.parse(localStorage.getItem("token"));
    const [openChqngeStatus, setOpenChangeStatus] = React.useState(false);

    useEffect(() => {

        selectCountry()
    }, []);
    const handleUniversityChange = (event) => {
        const {name, value} = event.target;
        setSelectedUniversity(value.split('#')[0]);
        setUniversity(value);
        setUniversityName(value.split('#')[1]);
    }


    const handleApplicationIntake = (e) => {
        setAppIntake(e.target.value)
    }
    const handleApplyClose = () => {
        setOpenApply(false);
    }

    const openChangeStatus = (id) => {
        setOpenChangeStatus(true)
    }
    const onDelete = async (index) => {
        alert(index)
        const tempArray = [...applications];
        console.log(tempArray)
        tempArray.splice(index, 1);
        console.log(tempArray)
        setApplications(tempArray);
        props.removeApply(index);
    };

    const onApply = async () => {
        const data = {
            country: country,
            university: selectedUniversity,
            universityName: universityName,
            intake: appIntake,
            countryName: countryName,
            status: 'APPLIED',
        }
        console.log(data)
        setApplications([...applications, data]);
        props.updateUniversity(data);
        setOpenApply(false)
    }

    const getApplications = async (value) => {
        let userArray = [];

        const applicationsList = await axios.get(
            `${VISA_URL}/users/getApplications?studentId=`+value,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (applicationsList.data.applications.data) {
            var tempArray=[];
            applicationsList.data.applications.data.forEach(async (uni) => {
                console.log(uni.university);
                var d ={
                     country: uni.country,
                     universityName: uni.university.name,
                     university: uni.university._id,
                     intake: uni.intake,
                     countryName: uni.countryName,
                     status: uni.status,
                 }
                 tempArray.push(d);
            });
            setApplications(tempArray)
            props.updateselectedUniversity(tempArray)

        } else {
            //setShowUniversity(false)
            alert("No University found for this country. ")
        }


    };

    const selectUniversity = async (value) => {
        const universityList = await axios.get(`${VISA_URL}/users/getCountryUniversities?countryId=` + value, {
            headers: {
                Authorization: `Bearer ${token}`, "Content-Type": "application/json",
            },
        });
        if (universityList?.data?.universityList) {
            setUniversityList(universityList?.data?.universityList);
            setShowUniversity(true)
        } else {
            setShowUniversity(false)
            alert("No University found for this country. ")
        }
    };
    const selectCountry = async () => {
        const countryList = await axios.post(`${VISA_URL}/users/getCountries`, {
            country: "",
        }, {
            headers: {
                Authorization: `Bearer ${token}`, "Content-Type": "application/json",
            },
        });
        setCountryList(countryList?.data?.counrtries);
    };
    const handleCountryChange = (event) => {
        const {name, value} = event.target;
        setCountry(value.split("#")[0]);
        setCountryName(value.split("#")[1])
        selectUniversity(value.split("#")[0]);
    }
    return (<Container>
            <Grid container
                  direction="column"
                  justifyContent="flex-end"
            >
                <Grid item>
                    <Grid direction="row"
                          justifyContent="flex-end"
                          alignItems="self-end">
                        <Box display="flex" justifyContent="flex-end">
                            <Button size="small" onClick={() => {
                                setOpenApply(true)
                            }}><Typography sx={{fontSize: 16}}>+ Add</Typography> </Button>
                        </Box>

                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer

                    >
                        <Table aria-label="simple table">
                            <TableHead
                                sx={{
                                    "& th": {
                                        color: "#666E7D", backgroundColor: "#F8F9FA", fontWeight: 'bold', fontSize: 14
                                    }
                                }}>
                                <TableCell align="left">University</TableCell>
                                <TableCell align="right">Country</TableCell>
                                <TableCell align="right">intake</TableCell>
                                {/*<TableCell align="right">Status</TableCell>*/}
                                <TableCell align="right">Actions</TableCell>

                            </TableHead>
                            {applications.map((value,index) => (<TableRow>
                                    <TableCell>{value?.universityName}</TableCell>
                                    <TableCell align="right">{value?.countryName}</TableCell>
                                    <TableCell align="right">{value?.intake}</TableCell>
                                    <TableCell align="right">
                                        <Grid sx={{width: "max-content"}}>
                                            <DeleteIcon onClick={() => onDelete(index)}
                                                        sx={{color: "#6681CD", mr: 0, cursor: "pointer"}}
                                            />

                                        </Grid>

                                    </TableCell>
                                </TableRow>))}
                        </Table>

                    </TableContainer>
                </Grid>
            </Grid>

            <Dialog
                open={openApply}
                onClose={handleApplyClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Apply for other university"}
                </DialogTitle>
                <DialogContent>
                    <Grid item mt={2}>
                        {countryList && <FormControl
                            sx={{textAlign: "left"}}
                            size="small"
                            fullWidth
                        >
                            <InputLabel
                                id="demo-simple-select-helper-label"
                                sx={{fontSize: 16}}
                            >
                                Country
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="outlined-required"
                                label="Country"
                                name="country"
                                fullWidth
                                defaultValue=""
                                value={country.split("#")[1]}
                                onChange={handleCountryChange}
                            >
                                {countryList?.map((items) => {
                                    return <MenuItem value={items._id + '#' + items.country}>{items.country}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>}
                    </Grid>
                    <Grid item mt={2}>
                        {showUniversity && <FormControl
                            sx={{textAlign: "left"}}
                            size="small"
                            fullWidth
                        >
                            <InputLabel
                                id="demo-simple-select-helper-label"
                                sx={{fontSize: 16}}
                            >
                                University
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="outlined-required"
                                label="University"
                                name="university"
                                fullWidth
                                defaultValue=""
                                value={university}
                                onChange={handleUniversityChange}

                            >
                                {universityList?.map((items) => {
                                    return <MenuItem value={items._id + '#' + items.name}>{items.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>}
                    </Grid>
                    <Grid item mt={2}>

                        <FormControl
                            sx={{textAlign: "left"}}
                            size="small"
                            fullWidth
                        >
                            <InputLabel
                                id="demo-simple-select-helper-label"
                                sx={{fontSize: 13}}
                            >
                                Intake
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="outlined-required"
                                label="Intake"
                                name="intake"
                                fullWidth
                                value={appIntake}
                                onChange={(e) => handleApplicationIntake(e)}
                            >
                                <MenuItem value={"january"}>January</MenuItem>
                                <MenuItem value={"february"}>February</MenuItem>
                                <MenuItem value={"march"}>March</MenuItem>
                                <MenuItem value={"april"}>April</MenuItem>
                                <MenuItem value={"may"}>May</MenuItem>
                                <MenuItem value={"june"}>June</MenuItem>
                                <MenuItem value={"july"}>July</MenuItem>
                                <MenuItem value={"august"}>August</MenuItem>
                                <MenuItem value={"september"}>September</MenuItem>
                                <MenuItem value={"october"}>October</MenuItem>
                                <MenuItem value={"november"}>November</MenuItem>
                                <MenuItem value={"december"}>December</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApplyClose}>Cancel</Button>
                    <Button onClick={onApply} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>);
});
