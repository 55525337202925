import { Alert } from '@mui/material';
import axios from 'axios';
import { VISA_URL } from '../../constant/constants';
import { useNavigate } from "react-router-dom";


export default axios.create({
  baseURL: VISA_URL
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {


    }
    else if (error.response.status === 401) {
      return window.location.href = '/login'

    }
    return error;
  });

