import * as React from 'react';
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { fetchToken, onMessageListener } from './firebase';
import { forwardRef, useRef, useImperativeHandle } from "react";
import Box from '@mui/material/Box';
import { FixedSizeList } from 'react-window';
import styles from './notifications.css';
import { Grid, Button, LinearProgress, TextField, Breadcrumbs, CardContent, Link } from '@mui/material';
import { Container } from "@mui/system";
import axios from "axios";
import { VISA_URL } from "../../constant/constants";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export default function NotificationsList(props) {

    const [show, setShow] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const token = JSON.parse(localStorage.getItem("token"));
    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
        console.log(value)
        setPage(value);
        getNotifications(value);
    };

    const navigate = useNavigate();

    useEffect(() => {

        getNotifications(1);

    }, []);

    const handleRowItemClicked = (value, edit) => {
        navigate("/Viewenquirylist", {
            state: {
                studentId: value,
                editable: edit,
                // status: selectedOption.value,
            },
        });
    };

    const getNotifications = async (val) => {
        const result = await axios.get(
            `${VISA_URL}/users/notifications?page=` + val + `&pageSize=10`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (result?.data?.notifications) {
            console.log(result?.data?.notifications?.data);
            setNotifications(result?.data?.notifications?.data)
            console.log(notifications);

        }
    }

    return (
        <Container maxWidth="lg" sx={{ pb: 3 }}>
            <Box >
                <Grid container maxWidth="md" spacing={6} >
                    <Grid item m={5}>
                        <Box  >
                            <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" href="/EnquiriesList">
                                    Home
                                </Link>
                                <Typography color="text.primary">Notifications</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                </Grid>
                <Container maxWidth="md">
                    <Grid container direction="row"
                        justifyContent="space-between"
                        alignItems="center" marginBottom={2} >
                        <Grid item >
                            {notifications && notifications.map((row) => {
                                return (
                                    <List sx={{ width: '100%', maxWidth: 660, bgcolor: 'background.paper' }}>
                                        <ListItem
                                            alignItems="flex-start"
                                            onClick={() => handleRowItemClicked(row.student, true)}>
                                            <ListItemAvatar>
                                                <Avatar alt={row.title} src="/static/images/avatar/1.jpg" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={row.title + ' ( ' + row.studentName + ' ) : ' + moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >

                                                        </Typography>
                                                        {row.text}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>

                                        <Divider variant="inset" component="li" />


                                    </List>)
                            })}

                        </Grid>



                    </Grid>
                    <Grid container maxWidth="md" spacing={6} mt={5} >
                        <Stack spacing={2}>
                            <Typography>Page: {page}</Typography>
                            <Pagination count={10} page={page} onChange={handleChange} />
                        </Stack>

                    </Grid>


                </Container>
            </Box>
        </Container>
    )
}
