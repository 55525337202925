import React, { useEffect, useState } from "react";
import "./EnquiriesList.css";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import axios from "axios";
import PropTypes from "prop-types";
import { VISA_URL } from "../../../constant/constants";
import TablePagination from "@mui/material/TablePagination";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { CSVLink } from "react-csv";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
  Box,
  Link,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import OutlinedInput from "@mui/material/OutlinedInput";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Bo } from "react-flags-select";
import { fontSize } from "@mui/system";
import { filterData } from "../../redux/action/Action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const EnquiriesList = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [selectedOption, setSelectedOption] = useState([{ id: 0, value: "" }]);
  const [fromdate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [userList, setUserList] = useState([]);
  const [updateState, setUpdateState] = useState(false);
  const [page, setPage] = React.useState(0);
  const [showIntake, setShowIntake] = useState(false);
  const [intakeValue, setIntakeValue] = useState(null);
  const [channelValue, setChannelValue] = useState(null);
  const location = useLocation();
  const [studentsearch, setStudentsearch] = useState("");
  const isAdmin = JSON.parse(localStorage.getItem("userData"));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [visaApprovalStudentId, setVisaApprovalStudentId] =
    React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);
  const [visaApprovedDialog, showVisaApprovedDialog] = useState(false);
  const theme = useTheme();
  const [visaApprovalDate, setVisaApprovalDate] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [currentChannelData, setCurrentChannelData] = useState([]);
  const [colSearch, setColSearch] = React.useState("");
  const [colSearchKey, setColSearchKey] = React.useState("");
  const [csvData, setCsvData] = useState("");
  const [showStatus, setShowStatus] = React.useState(false);
  const [dialogProps, setDailogProps] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [countryList, setCountryList] = useState();
  const [countrySearch, setCountrySearch] = useState("");
  const [channelList, setChannelList] = useState();
  const [channelSearch, setChannelSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [intakeSearch, setIntakeSearch] = useState("");

  const [searchFilter, setFilterSearch] = useState({
    startDate: fromdate || "",
    endDate: toDate || "",
    colName: colSearchKey || "",
    colValue: colSearch || "",
    country: countrySearch || "",
    status: statusSearch || "",
    channel: channelSearch || "",
    intake: intakeSearch || "",
  });
  var searchStatus = useSelector((store) => store.searchStatus.status);

  const getFilterData = useSelector((store) => store.saveFilterData.filterData);
 
  const dispatch = useDispatch();
  var searcStatusPending = useSelector(
    (store) => store.searchStatusPending.statusPending
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetFilter();
    setOpen(false);
  };

  const handleSearch = () => {
    navigate(location.state, {});
    fetchData();
  };

  const fetchData = async () => {
    let data =  {
      startDate: fromdate || "",
      endDate: toDate || "",
      colName: colSearchKey || "",
      colValue: colSearch || "",
      country: countrySearch || "",
      status: statusSearch || searcStatusPending.freezeStatusStatus || "" || searchStatus,
      channel: channelSearch || "",
      intake: intakeSearch || "",
      isFreeze: searcStatusPending.isFreez || "",
      freezeStatus: searcStatusPending.freezeStatus || "",
    }
    await axios
      .post(
        `${VISA_URL}/users/getStudents?page=` +
          page +
          "&pageSize=" +
          rowsPerPage,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setTotalCount(response?.data?.count);
        const data = response.data.data; 
        const filteredData = location.state?.filterData;
        if (filteredData!= null) {
          loadTable(filteredData);
        } else {
          loadTable(data);
        }

        dispatch(filterData(data));

        setOpen(false);
      });
  };
 


  const loadTable = (data) => {
    let rowArray = [];
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(a.statusUpdate);
      const dateB = new Date(b.statusUpdate);
      return dateB - dateA; // Sort in descending order
    });
    setRowsData(rowArray);
    let id = 1;
    data?.map((item, index) => {
      const dateString = item?.statusUpdate;
      let studentdate;
      if (dateString) {
        const updatedate = new Date(dateString);
        const year = updatedate?.getFullYear();
        const month = updatedate?.getMonth() + 1; // Add 1 to get 1-12 instead of 0-11
        const day = updatedate?.getDate();
        studentdate = `${day}-${month}-${year}`;
      } else {
        studentdate = "-";
      }
      let rowObject = {
        name: item?.name,
        fatherName: item?.fatherName,
        applicationStatus: item?.applicationStatus
          .replace(/([A-Z])/g, " $1")
          .trim()
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }),
        country: item?.country?.name,
        reference: item?.reference,
        intake: item?.intake,
        approvedChannel: item?.approvedChannel,
        assignedTo: item?.assignee?.name,
        key: item._id,
        lastUpdated: studentdate,
        studentCountry: item?.studentCountry,
        action: (
          <Grid sx={{ width: "max-content" }}>
            <EditIcon
              sx={{ color: "#6681CD", cursor: "pointer" }}
              onClick={() => handleEditStudentData(item._id, false)}
            />
            <VisibilityIcon
              sx={{ color: "#6681CD", ml: 1, cursor: "pointer" }}
              onClick={() => handleRowItemClicked(item._id, true)}
            />
            <DeleteIcon
              sx={{ color: "#6681CD", ml: 1, cursor: "pointer" }}
              onClick={() => handleDelete(item._id, true)}
            />
            {isAdmin.adminType == "SUPERADMIN" && (
              <AttachMoneyIcon
                sx={{ color: "#6681CD", ml: 1, cursor: "pointer" }}
                onClick={() => handlePackage(item._id, true)}
              />
            )}
          </Grid>
        ),
      };
      rowArray.push(rowObject);
    });

    setRowsData(rowArray);
  };

  const loadCsv = (data) => {
    let rowArray = [];
    //setRowsData(rowArray);
    let id = 1;
    data?.map((item, index) => {
      const dateString = item?.updatedAt;
      let studentdate;
      if (dateString) {
        const updatedate = new Date(dateString);
        const year = updatedate?.getFullYear();
        const month = updatedate?.getMonth() + 1; // Add 1 to get 1-12 instead of 0-11
        const day = updatedate?.getDate();
        studentdate = `${day}-${month}-${year}`;
      } else {
        studentdate = "-";
      }
      let rowObject = {
        name: item?.name,
        fatherName: item?.fatherName,
        applicationStatus: item?.applicationStatus
          .replace(/([A-Z])/g, " $1")
          .trim()
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }),
        //country: item?.country?.name,
        reference: item?.reference,
        intake: item?.intake,
        approvedChannel: item?.approvedChannel,
        assignedTo: item?.assignee?.name,
        lastUpdated: studentdate,
        lastUpdate: item.comments?.text,
        updatedBy: item.comments?.name,
        studentCountry: item?.studentCountry,
      };
      rowArray.push(rowObject);
    });
    downloadFile(rowArray, "users");
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchData();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "country") {
      setCountrySearch(value);
    } else if (name == "intake") {
      setIntakeSearch(value);
    } else if (name == "channel") {
      setChannelSearch(value);
    } else if (name == "status") {
      setStatusSearch(value);
    } else if (name == "user") {
      setColSearchKey(value);
    } else if (name == "nameText") {
      setColSearch(value);
    }
  };

  const handleStatusChange = (event) => {
    setColSearch(event.target.value);
  };

  const handleDelete = async (studentId) => {
    setDailogProps(studentId);
    setShowAlert(true);
  };

  const handleDeleteId = async (Id) => {
    const studentID = dialogProps;
    await axios
      .delete(`${VISA_URL}/users/deleteStudent?studentId=${studentID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setShowAlert(false);

          // <Alert severity="success">{response}</Alert>
          setUpdateState(!updateState);
          // navigate("/EnquiriesList")
        } else {
          console.log("Error", response.data.error);
        }
      })
      .catch((error) => {
        console.log("Something went wrong", error);
      });
  };

  const navigate = useNavigate();

  const handleRowItemClicked = (value, edit) => {
    // console.log("Filter Data while Navigating :: ", getFilterData);
    navigate("/Viewenquirylist", {
      state: {
        studentId: value,
        editable: edit,
        // filterData: getFilterData,
      },
    });
  };
  const handlePackage = async (studentId, value) => {
    const userList = await axios.get(
      `${VISA_URL}/users/getPackage?studentId=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    let responseData = userList;
    const packageData = responseData.data.packageData;
    setLoading(false);
    navigate("/UserPackage", {
      state: {
        studentId: studentId,
        packageData: responseData?.data?.packageData,
      },
    });
  };
  const handleEditStudentData = (value, edit) => {
    navigate("/personalEdit", {
      state: { studentId: value, editable: edit },
    });
  };

  const handleOnDateSearch = async () => {
    resetFilter();
    setOpen(true);
    // fetchData();
  };
  const handleOnNameSearch = async (event) => {
    setStudentsearch(event.target.value);
    setColSearch(event.target.value);
  };

  const selectCountry = async () => {
    let userArray = [];
    const countryList = await axios.post(
      `${VISA_URL}/users/getCountries`,
      {
        country: "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setCountryList(countryList?.data?.counrtries);
  };

  const getChannelsListData = async () => {
    const result = await axios.get(`${VISA_URL}/users/getChannelList`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setChannelList(result?.data?.channels);
  };

  useEffect(() => {
    // callFilterData();
    selectCountry();
    getChannelsListData();
    fetchData();
    if (!token) {
      navigate("/login");
    }
  }, [updateState, page, rowsPerPage]);

  const downloadFile = (fileData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = "xlsx";
    const ws = XLSX.utils.json_to_sheet(fileData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + "xlsx");
  };

  const resetFilter = () => {
    setToDate("");
    setFromDate("");
    setColSearchKey("");
    setColSearch("");
    setCountrySearch("");
    setStatusSearch("");
    setChannelSearch("");
    setIntakeSearch("");
  };

  const getCsvFileData = async () => {
    let statusFilter = "";
    if (location.state != null) {
      statusFilter = location.state.status;
    } else {
      statusFilter = statusSearch;
    }
    await axios
      .post(
        `${VISA_URL}/users/downloadStudentData`,
        {
          startDate: fromdate || "",
          endDate: toDate || "",
          colName: colSearchKey || "",
          colValue: colSearch || "",
          country: countrySearch || "",
          status: statusFilter,
          channel: channelSearch || "",
          intake: intakeSearch || "",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadCsv(response?.data.data);
        //setCsvData(response?.data?.dbRes);
      });
  };

  const getCountriesName = (names) => {
    var countryNames = "";
    for (var i = 0; i < names.length; i++) {
      if (names[i].countryName)
        countryNames = countryNames + " " + names[i].countryName;
    }
    return countryNames;
  };

  if (loading) {
    return (
      <div className="loader-container">
        <Circles type="Puff" color="#3081E8" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="main-container-enquiry-list">
      <Dialog
        fullScreen={fullScreen}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowAlert(false)}>
            Cancel
          </Button>
          <Button autoFocus onClick={(e) => handleDeleteId(e)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <Link
            underline="hover"
            className="font-link"
            color="inherit"
            href="/"
          >
            Home
          </Link>
          <Typography className="font-link" color="text.primary">
            Enquiries
          </Typography>
        </Breadcrumbs>
      </Box>

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="right"
        alignItems="right"
        pb={1}
        ori
      >
        <Grid item>
          <Button onClick={handleOnDateSearch}>
            <SearchIcon size={"small"} /> Search
          </Button>
        </Grid>
        <Grid item>
          {/* { <CSVLink data={rowsData} filename={"students-data.csv"}>
            Download CSV
          </CSVLink>} */}
          <Button onClick={getCsvFileData}>
            <DownloadIcon />
            Download
          </Button>
        </Grid>
        <Grid item>
          <Link href="/personalAdd">
            <Button>
              <AddIcon />
              Add Case
            </Button>
          </Link>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  color: "#666E7D",
                  backgroundColor: "#F8F9FA",
                  fontWeight: "bold",
                  fontSize: 14,
                },
              }}
            >
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Country</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Reference</TableCell>
              <TableCell align="left">Assigned To</TableCell>
              <TableCell align="left">Intake</TableCell>
              <TableCell align="left">Last Updated</TableCell>
              <TableCell align="left">channel</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData &&
              rowsData?.map((row) => (
                <TableRow
                  key={row?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" className="font-link">
                    {row?.name}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    <Tooltip title={getCountriesName(row.studentCountry)}>
                      <div>{row.studentCountry[0]?.countryName}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.applicationStatus}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.reference}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.assignedTo}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.intake}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.lastUpdated}
                  </TableCell>
                  <TableCell align="left" className="font-link">
                    {row?.approvedChannel}
                  </TableCell>
                  <TableCell align="center" className="font-link">
                    {row?.action}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 30]}
                colSpan={3}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Search</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            pb={1}
            ori
          >
            <Grid item>
              <TextField
                type="date"
                InputLabelProps={{ shrink: true }}
                label="From"
                margin={"normal"}
                size={"small"}
                //defaultValue={moment().format("YYYY-MM-DD")}
                value={fromdate}
                onChange={(event) => setFromDate(event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="To"
                  margin={"normal"}
                  sx={{ ml: 1 }}
                  size={"small"}
                  value={toDate}
                  //defaultValue={moment().format("YYYY-MM-DD")}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item>
              {countryList && (
                <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">Country</InputLabel>
                  <Select
                    name="country"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={countrySearch}
                    label="Search"
                    onChange={handleChange}
                  >
                    {countryList?.map((items) => {
                      return (
                        <MenuItem value={items._id}>{items.country}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Status</InputLabel>
                <Select
                  // labelId="demo-select-small-label"
                  name="status"
                  id="demo-select-small"
                  value={statusSearch}
                  label="Search"
                  onChange={handleChange}
                >
                  <MenuItem value={"incompleteDocuments"}>
                    Incomplete documents
                  </MenuItem>
                  <MenuItem value={"applicationOnAssessment"}>
                    Application on assessment
                  </MenuItem>
                  <MenuItem value={"offerApplied"}>Offer Applied</MenuItem>
                  <MenuItem value={"offerReceived"}>Offer Received</MenuItem>
                  <MenuItem value={"fileLogged"}>File Logged</MenuItem>
                  <MenuItem value={"visaApproved"}>Visa Approved</MenuItem>
                  <MenuItem value={"visaDecline"}>Visa Decline</MenuItem>
                  <MenuItem value={"onshore"}>Onshore</MenuItem>
                  <MenuItem value={"refund"}>Refund</MenuItem>
                  <MenuItem value={"freezed"}>Freezed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Channel</InputLabel>
                <Select
                  // labelId="demo-select-small-label"
                  name="channel"
                  id="demo-select-small"
                  value={channelSearch}
                  label="channel"
                  onChange={handleChange}
                >
                  {channelList?.map((items) => {
                    return (
                      <MenuItem value={items._id}>{items.channel}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Intake</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="intake"
                  value={intakeSearch}
                  label="Search"
                  onChange={handleChange}
                >
                  <MenuItem value={"JAN"}>January</MenuItem>
                  <MenuItem value={"FEB"}>February</MenuItem>
                  <MenuItem value={"MARCH"}>March</MenuItem>
                  <MenuItem value={"JAN"}>April</MenuItem>
                  <MenuItem value={"FEB"}>May</MenuItem>
                  <MenuItem value={"MARCH"}>Jun</MenuItem>
                  <MenuItem value={"JAN"}>July</MenuItem>
                  <MenuItem value={"FEB"}>August</MenuItem>
                  <MenuItem value={"MARCH"}>September</MenuItem>
                  <MenuItem value={"JAN"}>October</MenuItem>
                  <MenuItem value={"FEB"}>November</MenuItem>
                  <MenuItem value={"MARCH"}>December</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">User</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={colSearchKey}
                  name="user"
                  label="Search"
                  onChange={handleChange}
                >
                  <MenuItem value={"name"}>Student</MenuItem>
                  <MenuItem value={"userName"}>Assignee</MenuItem>
                  <MenuItem value={"reference"}>Reference</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 0, minWidth: 120 }} size="small">
                <TextField
                  label="Search"
                  InputProps={{
                    type: "search",
                  }}
                  //sx={{ width: 200 }}
                  name="nameText"
                  margin={"normal"}
                  size={"small"}
                  value={colSearch}
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetFilter}>Reset</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSearch}>Search</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
