import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "../visaenquiry/Education.css";
import Paper from "@mui/material/Paper";
import AddIcon from '@mui/icons-material/Add';
import {
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,

} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from "@mui/material/Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import styles from "../visaenquiry/VisaEnquiry.module.css";
import Steppers from "../visaenquiry/Stepper";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { saveEducationWorkDetail, saveUserInfo } from "../redux/action/Action";
import { useNavigate } from "react-router-dom";
import { months } from "moment";

const EducationAdd = () => {

    let userDetail = useSelector((state) => state.saveEducationWorkDetails);
    let temp = useSelector((state) => state.saveUserInfo);
    const dispatch = useDispatch();
    let workExperience = (temp.userInfo.workExperience);
    let education = (temp.userInfo.education);
    let testTaken = (temp.userInfo.testTaken);
    const navigate = useNavigate();
    const Regex = /^[A-Za-z0-9\s.,-]*$/;
    const percentregex = /^[0-9%]*$/;
    const [able, setAble] = useState(true);
    const [showScore, setShowScore] = useState(false);
    const [educationData, setEducationData] = useState([
        {
            year: "",
            qualification: "",
            percentage: "0",
            institutionName: "",
            board: "",
            regular: false,
        }
    ])
    const [educationRow, setEducationRow] = useState({})
    const [mandatory, setMandatory] = useState('isApplicable')
    const [workExperienceData, setWorkExperienceData] = useState([{
        years: "",
        months: "",
        employer: "",
        designation: "",
        industryType: "",
        workSchedule: "",
    }]);
    const [testTakenData, setTestTakenData] = useState({
        "isApplicable": true,
        "testName": "IELTS",
        "readScore": 0,
        "writeScore": 0,
        "speakingScore": 0,
        "listeningScore": 0,
        "overallScore": 0,
        "fieldOfStudy": "",
        "instituteOfInterest": ""
    });
    const currentYear = new Date().getFullYear();
    const startYear = 1990;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }
    const areMandatoryFieldsFilled = (educationItem) => {
        return (
            educationItem.qualification &&
            educationItem.percentage &&
            educationItem.institutionName &&
            educationItem.board
        );
    };

    const handleEducationAdd = () => {
        console.log(educationData)
        const allValid = educationData.every((educationItem) => educationItem.isValid);

        if (allValid) {
            setEducationData([...educationData, { isValid: false }]);
        } else {
            alert('Please fill all mandatory fields.');
        }
    };

    const handleServiceAddWork = () => {
        console.log(workExperienceData)
        if (workExperienceData) {
            setWorkExperienceData([
                ...workExperienceData,
                {
                    years: "",
                    months: "",
                    employer: "",
                    designation: "",
                    industryType: "",
                    workSchedule: "",
                },
            ]);
        } else {
            setWorkExperienceData([
                {
                    years: "",
                    months: "",
                    employer: "",
                    designation: "",
                    industryType: "",
                    workSchedule: "",
                }
            ]);
        }
    };

    const handleEducationRemove = (index) => {
        if (educationData.length === 1) {
            return;
        }
        console.log(index)
        const educatioinArray = [...educationData];
        educatioinArray.splice(index, 1);
        setEducationData(educatioinArray);
    };

    const handleExperinceRemove = (index) => {
        if (workExperienceData.length === 1) {
            return;
        }
        console.log(index)
        const workExperinceArray = [...workExperienceData];
        workExperinceArray.splice(index, 1);
        setWorkExperienceData(workExperinceArray);
    };

    const backFunction = (e) => {
        navigate("/documentAdd");
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;

        let educationArray = [...educationData];
        if (value === "Regular") {
            educationArray[index][name] =true; 
        } else if (value === "Distance") {
            educationArray[index][name] = true; 
        } else {
            educationArray[index][name] = value;
        }
        const updatedEducationData = [...educationArray];
        const updatedEducationItem = { ...updatedEducationData[index] };
        updatedEducationItem.isValid = areMandatoryFieldsFilled(updatedEducationItem);
        updatedEducationData[index] = updatedEducationItem;
        setEducationData(updatedEducationData);
    };
    const handleInputChangeforWorkExperience = (event, index) => {
        const { name, value } = event.target;
        let workExperinceArray = [...workExperienceData];
        workExperinceArray[index][name] = value;
        setWorkExperienceData(workExperinceArray)
        console.log(workExperienceData);
    }
    const [selectedValue, setSelectedValue] = useState(null); // Initialize with null

    const handleOnCheckTestTaken = (event) => {
        setSelectedValue(event.target.value);
        const { name, value } = event.target;
        let tests = testTakenData;
        if (value !== 'isApplicable') {
            setShowScore(true)
            setTestTakenData((prevState) => ({
                ...prevState,
                [name]: value,
                isApplicable: true,

            }));
            setMandatory(value)
        } else {
            setShowScore(false);
            setTestTakenData((prevState) => ({
                ...prevState,
                isApplicable: false,

            }));
            setMandatory(value)
        }
    };
    const handleOnCheckforNotApplicable = (event) => {
        const { name, value } = event.target;

    };
    const handleOnSubmit = (e) => {
        console.log(educationData)
        console.log(workExperienceData)
        console.log(testTakenData)
        e.preventDefault();
        if (

            testTakenData.fieldOfStudy === undefined ||
            testTakenData.instituteOfInterest === undefined
        ) {
            alert("Please fill all the feilds");
        } else if (
            (testTakenData.readScore === (undefined || "") ||
                testTakenData.writeScore === (undefined || "") ||
                testTakenData.listeningScore === (undefined || "") ||
                testTakenData.speakingScore === (undefined || "") ||
                testTakenData.overallScore === (undefined || ""))) {
            alert("Please fill all feilds of test taken");
        } else if (
            educationData[0].year === (undefined || "") ||
            educationData[0].qualification === (undefined || "") ||
            educationData[0].board === (undefined || "") ||
            educationData[0].percentage === (undefined || "") ||
            educationData[0].regular === undefined ||
            educationData[0].institutionName === (undefined || "")
        ) {
            alert("please add education qualification");

        } else {

            let userInfo = temp.userInfo;
            userInfo.education = educationData;
            userInfo.workExperience = workExperienceData;
            userInfo.testTaken = testTakenData;

            console.log(userInfo);
            dispatch(saveUserInfo);
            navigate("/visaAdd");


        }
    };
    // const token = JSON.parse(localStorage.getItem("token"));
    useEffect(() => {
        if (testTaken) {
            setTestTakenData(testTaken)
            setMandatory(testTaken.testName);

            if (testTaken.testName == 'IELTS' || testTaken.testName == 'PTE') {
                setShowScore(true)
            }
        }

        if (education) {
            setEducationData(education)
        }
        if (workExperience) {
            setWorkExperienceData(workExperience)
        }




    }, [])

    function removeValuesWithKey(key, array) {
        array.map((item) => {
            if (Object.keys(item).includes(key)) {
                delete item[key];
            }
            else {
                Object.keys(item).map((itemKey) => {
                    if (Array.isArray(item[itemKey])) {
                        removeValuesWithKey(key, item[itemKey]);
                    }
                })
            }
        })
    }

    // useEffect(() => {
    //     if (!token) {
    //         navigate("/login");
    //     }
    //     if (data.education) {
    //         setEducation(data.education);
    //     }
    //     if (data.workaddmore) {
    //         setWorkAddmore(data.workaddmore);
    //     }
    // }, []);

    function update(target, src) {
        const res = {};
        Object.keys(target)
            .forEach(k => res[k] = (src[k] ?? target[k]));
        return res;
    }

    return (
        <Container maxWidth="mx" className={styles.containerFix}>
            <Grid item sx={{ flexWrap: "wrap" }}>
                <Box className={styles.breadcrumFix}>
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/EnquiriesList">
                            Home
                        </Link>

                        <Typography color="text.primary">Enquiry</Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Steppers activeSteps={2}></Steppers>
                </Box>

                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                                m: 2,

                                width: "-webkit-fill-available",
                            },
                        }}
                    >
                        <Paper elevation={3}>
                            <Grid item mt={1} mb={2} sx={{ fontSize: "16px", fontWeight: "bold", width: "-webkit-fill-available" }}>
                                Education
                            </Grid>
                            {/* <div className="Education"> */}
                            <Grid
                                item
                                sx={{
                                    pl: 2,
                                    pr: 2,
                                    flexWrap: "wrap",
                                    flex: 1,
                                }}
                            >
                                {console.log(educationData)}
                                {educationData ? educationData.map((educationItem, index) => (
                                    <Grid
                                        item
                                        sx={{ display: "flex", gap: 1, p: 1, flexWrap: "wrap" }}
                                    >

                                        <Grid item sx={{ flex: 1 }}>
                                            <FormControl
                                                sx={{ textAlign: "left" }}
                                                size="small"
                                                fullWidth
                                                required
                                            >
                                                <InputLabel id="demo-simple-select-helper-label">
                                                    Year
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="outlined-required"
                                                    label="Year"
                                                    name="year"
                                                    required
                                                    defaultValue=""
                                                    onChange={(event) => handleInputChange(event, index)}
                                                    value={educationItem?.year}
                                                >
                                                    {years && years?.map((year) => {
                                                        return <MenuItem value={year}>{year}</MenuItem>;
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                id="outlined-required"
                                                label="Qualification"
                                                name="qualification"
                                                onChange={(event) => handleInputChange(event, index)}
                                                value={
                                                    educationItem?.qualification
                                                }
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);
                          
                                                    if (!Regex.test(key)) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                id="outlined-required"
                                                label="Percent"
                                                name="percentage"
                                                onChange={(event) => handleInputChange(event, index)}
                                                value={
                                                    educationItem?.percentage
                                                }
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);
                          
                                                    if (!percentregex.test(key)) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                id="outlined-required"
                                                label="Institute Name"
                                                name="institutionName"
                                                onChange={(event) => handleInputChange(event, index)}
                                                value={
                                                    educationItem?.institutionName

                                                }
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}  
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                id="outlined-required"
                                                label="Board/University"
                                                name="board"
                                                onChange={(event) => handleInputChange(event, index)}
                                                value={educationItem?.board}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <FormControl
                                                sx={{ textAlign: "left" }}
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel id="demo-simple-select-helper-label">
                                                    Regular/Distance
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="outlined-required"
                                                    label="Regular/Distance"
                                                    name="regular"
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    defaultValue=""
                                                    value={
                                                        educationItem?.regular ? "Regular" : "Distance"
                                                    }
                                                    onChange={(event) => handleInputChange(event, index)}
                                                >
                                                    <MenuItem value="Regular">Regular</MenuItem>
                                                    <MenuItem value="Distance">Distance</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item sx={{ flex: 0, pr: 2, mt: 1 }}>
                                            <ClearIcon onClick={() => handleEducationRemove(index)} />

                                        </Grid>
                                    </Grid>
                                )) : <></>}
                                <Grid item sx={{ display: "flex", gap: 1, ml: 1 }}>
                                    <Grid item sx={{ pt: 2 }}>
                                        <AddIcon onClick={handleEducationAdd} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{ fontSize: "16px", fontWeight: "bold", width: "-webkit-fill-available" }}>
                                Work Experience
                            </Grid>
                            <Grid>
                                {workExperienceData.map((workExperienceItem, index) => (
                                    <Grid
                                        item
                                        sx={{
                                            display: "flex",
                                            gap: 1,
                                            pl: 3,
                                            pr: 3,
                                            pt: 3,
                                            flexWrap: "wrap",
                                        }}
                                    >

                                        <Grid item sx={{ flex: 1 }}>
                                            <FormControl
                                                sx={{ textAlign: "center" }}
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel>Years</InputLabel>
                                                <Box></Box>
                                                <Select
                                                    label="Years"
                                                    name="years"
                                                    size="small"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}

                                                    onChange={(event) =>
                                                        handleInputChangeforWorkExperience(event, index)
                                                    }
                                                    value={workExperienceItem?.years}
                                                >
                                                    {Array.from({ length: 31 }, (_, i) => (
                                                        <MenuItem key={i} value={i}>
                                                            {i}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <FormControl
                                                sx={{ textAlign: "center" }}
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel>Months</InputLabel>
                                                <Select
                                                    label="Months"
                                                    name="months"
                                                    size="small"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) =>
                                                        handleInputChangeforWorkExperience(event, index)
                                                    }
                                                    value={workExperienceItem?.months}
                                                >
                                                    {Array.from({ length: workExperienceItem?.years === 0 ? 12 : 13 }, (_, i) => (
                                                        <MenuItem key={i} value={i}>
                                                            {i}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                id="outlined-required"
                                                label="Employeer"
                                                name="employer"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(event) =>
                                                    handleInputChangeforWorkExperience(event, index)
                                                }
                                                value={workExperienceItem?.employer}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>
                                            <TextField
                                                size="small"
                                                id="outlined-required"
                                                label="Designation"
                                                name="designation"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(event) =>
                                                    handleInputChangeforWorkExperience(event, index)
                                                }
                                                value={workExperienceItem?.designation}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sx={{ flex: 1 }}>

                                            <TextField
                                                size="small"
                                                id="outlined-required"
                                                label="Industry Type"
                                                name="industryType"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(event) =>
                                                    handleInputChangeforWorkExperience(event, index)
                                                }
                                                value={workExperienceItem?.industryType}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={{ flex: 1 }}>

                                            <Grid item sx={{ flex: 1 }}>
                                                <FormControl
                                                    sx={{ textAlign: "left" }}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <InputLabel id="demo-simple-select-helper-label">
                                                        Full/Part Time
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="outlined-required"
                                                        label="Full/Part Time"
                                                        name="workSchedule"
                                                        defaultValue=""
                                                        value={workExperienceItem?.workSchedule}
                                                        onChange={(event) =>
                                                            handleInputChangeforWorkExperience(event, index)
                                                        }
                                                    >
                                                        <MenuItem value="Full">Full Time</MenuItem>
                                                        <MenuItem value="Part">Part Time</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ flex: 0, pr: 2, mt: 1 }}>
                                            <ClearIcon onClick={() => handleExperinceRemove(index)} />
                                        </Grid>

                                    </Grid>
                                ))}
                                <Grid item sx={{ display: "flex", gap: 1, pl: 3, pt: 2 }}>
                                    <Grid item sx={{ pt: 1 }}>
                                        <AddIcon onClick={handleServiceAddWork} />
                                    </Grid>

                                </Grid>
                                <Grid sx={{ display: "flex", pl: 3, pr: 3, gap: 1 }}>
                                    <Grid sx={{ fontSize: "16px", fontWeight: "bold", width: "-webkit-fill-available" }}>
                                        <Grid item mt={1} >
                                            Test Taken
                                        </Grid>
                                        <Grid item sx={{ display: "flex", width: "fit-content" }}>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    value={selectedValue}
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value={'IELTS'} name="testName"
                                                        onChange={handleOnCheckTestTaken}
                                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                                                        control={<Radio />} label="IELTS" />
                                                    <FormControlLabel value={"PTE"} name="testName"
                                                        onChange={handleOnCheckTestTaken}
                                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                                                        control={<Radio />} label="PTE" />
                                                    <FormControlLabel value='isApplicable' name="isApplicable"
                                                        onChange={handleOnCheckTestTaken}
                                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                                                        control={<Radio />} label="Not Required" />

                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {(showScore && testTakenData.isApplicable) && (
                                            <div
                                                className="Reading_Writing_Feild"

                                            >
                                                <Grid sx={{ flex: 1 }}>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        size="small"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Reading"
                                                        name="readScore"
                                                        type="number"
                                                        inputProps={{
                                                            max: 9,
                                                            min :0
                                                        }}
                                                        onChange={handleOnCheckTestTaken}
                                                        value={testTakenData?.readScore}

                                                    />
                                                </Grid>
                                                <Grid sx={{ flex: 1 }}>
                                                    <Grid disabled={able}>
                                                        {/* <p className="Writing">Writing</p> */}
                                                    </Grid>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        size="small"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        // disabled={able}
                                                        label="Writing"
                                                        type="number"
                                                        name="writeScore"
                                                        inputProps={{
                                                            max: 40,
                                                            min :0
                                                        }}
                                                        onChange={handleOnCheckTestTaken}
                                                        value={testTakenData?.writeScore}
                                                    />
                                                </Grid>
                                                <Grid sx={{ flex: 1 }}>
                                                    <Grid disabled={able}>
                                                        {/* <p className="Speaking">Speaking</p> */}
                                                    </Grid>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        size="small"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        // disabled={able}
                                                        label="Speaking"
                                                        name="speakingScore"
                                                        type="number"
                                                        inputProps={{
                                                            max: 9,
                                                            min :0
                                                        }}
                                                        onChange={handleOnCheckTestTaken}
                                                        value={testTakenData?.speakingScore}
                                                    />
                                                </Grid>
                                                <Grid sx={{ flex: 1 }}>
                                                    <Grid disabled={able}>
                                                        {/* <p className="Listening">Listening</p> */}
                                                    </Grid>
                                                    <TextField
                                                        id="outlined-size-small"
                                                        size="small"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        // disabled={able}
                                                        label="Listening"
                                                        name="listeningScore"
                                                        type="number"
                                                        inputProps={{
                                                            max: 40,
                                                            min :0
                                                        }}
                                                        onChange={handleOnCheckTestTaken}
                                                        value={testTakenData?.listeningScore}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ flex: 1 }}>
                                                    <TextField
                                                        size="small"
                                                        required
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        id="outlined-required"
                                                        label="Overall Score"
                                                        name="overallScore"
                                                        type="number"
                                                        onChange={handleOnCheckTestTaken}
                                                        value={testTakenData?.overallScore}
                                                        inputProps={{
                                                            max: 9,
                                                            min :0
                                                        }}
                                                    />
                                                </Grid>
                                            </div>)}
                                    </Grid>


                                </Grid>
                                <Grid item sx={{ display: "flex", pl: 3, pr: 3, gap: 1 }}>
                                    {testTakenData.isApplicable && (
                                        <Grid sx={{ width: "-webkit-fill-available", mt: 2 }}>
                                            <p className="Study">Intended Field of Study</p>
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                name="fieldOfStudy"
                                                onChange={handleOnCheckTestTaken}
                                                InputLabelProps={{ shrink: true }}
                                                value={testTakenData?.fieldOfStudy}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    {testTakenData.isApplicable && (
                                        <Grid sx={{ width: "-webkit-fill-available", mt: 2 }}>
                                            <p className="Institute">Institute of Interest</p>
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                name="instituteOfInterest"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleOnCheckTestTaken}
                                                value={testTakenData?.instituteOfInterest}
                                                onKeyPress={(e) => {
                                                    const key = String.fromCharCode(e.which);

                                                    if (!Regex.test(key)) {
                                                        e.preventDefault();
                                                    }
                                                }}  
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                            />

                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sx={{ gap: 1, justifyContent: "center", display: "flex", p: 2 }}
                            >
                                <Grid>
                                    <Button variant="outlined"
                                        onClick={backFunction}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button variant="outlined"
                                        onClick={handleOnSubmit}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};
export default EducationAdd;