import React from "react";
import Backgroundimage from "../static/images/image1.png";
import "./Background.css";
import ResetPassword from "./Resetpassword";
const BackgroundResetPassword = () => {
  return (
    <div className="reset-containers">
      <ResetPassword/>
    </div>
  );
};

export default BackgroundResetPassword;
