import React, { useEffect, useState, useNavigate } from "react";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField, Container, LinearProgress, FormControl, InputLabel, Select, MenuItem, Button, Breadcrumbs, Box, Link, Typography, IconButton, Alert } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Stack from '@mui/material/Stack';
import AWS from 'aws-sdk';
import { VISA_URL } from "../../constant/constants";
import axios from "axios";
import CircularJSON from "circular-json";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Circles } from 'react-loader-spinner';

window.Buffer = window.Buffer || require("buffer").Buffer;
const token = JSON.parse(localStorage.getItem("token"));

const AddUniversity = () => {

  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [universities, setUniversities] = useState([]);
  const [deleteAlertVisible, setDeleteAlertVisibility] = useState(false);
  const [deleteUniId, setDeleteUniId] = useState(null);
  const [universty, setUniversty] = useState({ name: "", link: "", country: "", city: "" });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const s3 = new AWS.S3();
  const S3_BUCKET = 'visa-s3-bucket';
  const REGION = 'ap-south-1';
  const ACCESS_KEY = 'AKIA5OUM5OKDFXAPGDFM';
  const SECRET_ACCESS_KEY = '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';
  const [searchValue, setSearchValue] = useState('');
  const [searchedTableRows, setSearchedTableRows] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState('');
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateId, setUpdateId] = useState('');


  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
    signatureVersion: 'v4',
  });

  const selectCountry = async () => {
    let userArray = [];
    const countryList = await axios.post(
      `${VISA_URL}/users/getCountries`,
      {
        country: "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(countryList?.data?.counrtries);
    setCountryList(countryList?.data?.counrtries);

  };

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })
  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleUpdateClose = () => {
    setShowUpdate(false);
  };

  useEffect(() => {
    loadUniversities();
    selectCountry();
  }, []);

  const loadUniversities = async (e) => {
    await axios.get(`${VISA_URL}/users/getUniversities`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      .then(response => {
        console.log('Universities ==> ', response);
        setLoading(false);
        let rowsData = [];
        let key = 1;

        response.data.apiRes.map((item) => {
          console.log(item?.country._id)
          rowsData.push({ id: item?._id, key: key, name: item?.name, location: item?.location, country: item?.country.country, countryId: item?.country._id, link: item?.brochureLink });
          key++;
        });
        setUniversities(rowsData);
      }
      );
  };

  const addNewUniversity = async () => {


    if (!universty.name) {
      alert("Add University Name ")
      return false;
    }
    if (!universty.country) {
      alert("Add Country Name")
      return false;
    }
    if (!universty.location) {
      alert("Add City Name")
      return false;
    }

    var url = `${VISA_URL}/users/addUniversity`;
    var data = {
      "universityName": universty.name,
      "link": universty.link,
      "country": universty.country,
      "location": universty.location
    }

    setLoader(true);
    const result = await axios.post(url, CircularJSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).catch((error) => {
        setLoading(false);
      });;;

    if (result.data.response == "success") {
      setOpen(false);
      setLoading(false);
      setLoader(false);
      setUniversty({ name: "", link: "", country: "", city: "" });
      loadUniversities();
    }
    else {
      setOpen(false);
      setLoading(false);
      setLoader(false);
      setUniversty({ name: "", link: "", country: "", city: "" });
      alert('Some error while adding the country');
    }

  };

  const updateNewUniversity = async (labelId) => {


    if (!universty.name) {
      alert("Add University Name ")
      return false;
    }
    if (!universty.country) {
      alert("Add Country Name")
      return false;
    }
    if (!universty.location) {
      alert("Add City Name")
      return false;
    }

    var url = `${VISA_URL}/users/updateUniversity`;
    var data = {
      "universityName": universty.name,
      "link": universty.link,
      "country": universty.country,
      "location": universty.location,
      "id": updateId
    }

    setLoader(true);
    const result = await axios.post(url, CircularJSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).catch((error) => {
        setLoading(false);
      });;;

    if (result.data.response == "success") {
      setShowUpdate(false);
      setLoading(false);
      setLoader(false);
      setUniversty({ name: "", link: "", country: "", city: "" });
      loadUniversities();
    }
    else {
      setShowUpdate(false);
      setLoading(false);
      setLoader(false);
      setUniversty({ name: "", link: "", country: "", city: "" });
      alert('Some error while adding the country');
    }

  };

  const updateUniversity = (id, name, link, country, location, countryId) => {
    setShowUpdate(true);
    setCountry(countryId)
    setUpdateId(id);
    var data = {
      "name": name,
      "link": link,
      "country": countryId,
      "location": location,
    }
    console.log(countryId)

    setUniversty(data)
  }

  const openDialog = () => {

    setUniversty({
    "name": "",
    "link": "",
    "country": "",
    "location": ""
  })
  setCountry(null)
    setOpen(true)
  };




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log()
    setUniversty((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setCountry(value)

    setUniversty((prevState) => ({
      ...prevState,
      ['country']: value,
    }));



  };

  const handleFileChange = async (e) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: `${Date.now()}.${e.target.files[0].name}`,
      Body: e.target.files[0],
      ContentType: e.target.files[0].type,
    };
    const { Location } = await s3.upload(params).promise();

    setUniversty((prevState) => ({
      ...prevState,
      ['link']: Location,
    }));
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const deleteUniversity = async (id) => {
    setOpenDelete(true);
    setDeleteUniId(id);
  }

  const conductSearch = (value) => {
    setSearchValue(value);
    if (value == '') {
      setSearchedTableRows(universities);
    }
    else {
      let newRows = universities.filter((item) => {
        return item?.name?.includes(value);
      })
      setSearchedTableRows(newRows);
    }

  }

  const deleteUniversityConfirmed = async () => {
    setOpenDelete(false);
    setLoader(true)
    const result = await axios.delete(`${VISA_URL}/users/removeUniversity?id=` + deleteUniId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }).catch((error) => {
        setLoading(false);
      });;

    if (result.data.response == "success") {
      setOpenDelete(false);
      setLoading(false);
      setLoader(false);
      loadUniversities();
    }
    else {
      setLoading(false);
      setLoader(false);
      setDeleteAlertVisibility(false);
      alert('Some error while deleting the university');
    }
  }
  if (loading) {
    return (
      <div className="loader-container">
        <Circles type="Puff" color="#3081E8" height={100} width={100} />
      </div>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ pb: 3 }}>
      {
        loader &&
        <LinearProgress color="success" />
      }

      <Grid container maxWidth="lg" spacing={6} >
        <Grid item m={5}>
          <Box  >
            <Breadcrumbs maxItems={2} aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/EnquiriesList">
                Home
              </Link>
              <Typography color="text.primary">Universities</Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
      </Grid>

      <Container maxWidth="md">
        <Grid container direction="row"
          justifyContent="space-between"
          alignItems="center" marginBottom={2} >

          <Grid item >
            <TextField
              id="serach"
              placeholder="Search University ..."
              size="small"
              onChange={(e) => {
                conductSearch(e.target.value)
              }}
            />
          </Grid>
          <Grid item >
            <Button  autoFocus onClick={() => openDialog()} >
              <AddIcon sx={{ fontSize: 18 }} />Add </Button>
          </Grid>
        </Grid>



        <Dialog open={open}>
          <DialogTitle>Add University</DialogTitle>
          <DialogContent style={{ height: 250, width: 500 }}>
            <DialogContentText style={{ marginBottom: 0 }}>
            </DialogContentText>

            <Grid>

              <TextField
                label="Name"
                name="name"
                fullWidth
                margin={'normal'}
                size={'small'}
                value={universty.name}
                onChange={(event) => {
                  handleInputChange(event);
                }}
              />
            </Grid>
            <Grid>

              <TextField
                label="City"
                name="location"
                fullWidth
                value={universty.location}
                margin={'normal'}
                size={'small'}
                onChange={(event) => {
                  handleInputChange(event);
                }}
              />
            </Grid>
            <Grid item mt={2}>
              {countryList &&
                <FormControl
                  sx={{ textAlign: "left" }}
                  size="small"
                  fullWidth
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: 16 }}
                  >
                    Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="outlined-required"
                    label="Country"
                    name="country"
                    fullWidth
                    defaultValue=""
                    value={country}
                    onChange={handleSelectChange}
                  >
                    {countryList?.map((items) => {
                      return <MenuItem key={items._id} value={items._id}>{items.country}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              }
            </Grid>
            <Grid item >

              <div style={{ marginTop: 30 }}>
                <input name="link" type="file" onChange={handleFileChange} accept="image/png, image/gif, image/jpeg, application/pdf" />
              </div>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button  onClick={() => { setOpen(false) }}>Cancel</Button>
            <Button  autoFocus onClick={() => addNewUniversity()}>Add</Button>

          </DialogActions>
        </Dialog>
        {universty &&
          <Dialog open={showUpdate}
            onClose={handleUpdateClose}>
            <DialogTitle>Update University</DialogTitle>
            <DialogContent style={{ height: 250, width: 500 }}>
              <DialogContentText style={{ marginBottom: 0 }}>
              </DialogContentText>

              <Grid>

                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  margin={'normal'}
                  size={'small'}
                  value={universty.name}
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />
              </Grid>
              <Grid>

                <TextField
                  label="City"
                  name="location"
                  fullWidth
                  value={universty.location}
                  margin={'normal'}
                  size={'small'}
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />
              </Grid>
              <Grid item mt={2}>
                {countryList &&
                  <FormControl
                    sx={{ textAlign: "left" }}
                    size="small"
                    fullWidth
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      sx={{ fontSize: 16 }}
                    >
                      Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="outlined-required"
                      label="Country"
                      name="country"
                      fullWidth
                      defaultValue=""
                      value={country}
                      onChange={handleSelectChange}
                    >
                      {countryList?.map((items) => {
                        return <MenuItem key={items._id} value={items._id}>{items.country}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                }
              </Grid>
              <Grid item >

                <div style={{ marginTop: 30 }}>
                  <input name="link" type="file" onChange={handleFileChange} accept="image/png, image/gif, image/jpeg, application/pdf" />
                </div>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setShowUpdate(false) }}>Cancel</Button>
              <Button  autoFocus onClick={() => updateNewUniversity()}>Update</Button>

            </DialogActions>
          </Dialog>
        }
        {
          openDelete &&
          <Dialog
            fullScreen={fullScreen}
            open={openDelete}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Are sure you want to delete university ?"}

            </DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button  autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button  onClick={deleteUniversityConfirmed} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        }
        <TableContainer component={Paper} >
          <Table aria-label="customized table">
            <TableHead sx={{
              "& th": {
                color: "#666E7D",
                backgroundColor: "#F8F9FA",
                color: "#666E7D",
                fontWeight:'bold',
                fontSize:14
              }
            }}>
              <TableRow sx={{
                 "& th": {
                  color: "#666E7D",
                  backgroundColor: "#F8F9FA",
                  color: "#666E7D"
                }
                
              }}>
                <TableCell>ID</TableCell>
                <TableCell align="left"><h6>Universities</h6></TableCell>
                <TableCell align="left"><h6>Country</h6></TableCell>
                <TableCell align="left"><h6>City</h6></TableCell>
                <TableCell align="left"><h6>Attachments</h6></TableCell>
                <TableCell align="left"><h6>Action</h6></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchedTableRows !== null ? searchedTableRows : universities).map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.key}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.country}</TableCell>
                  <TableCell align="left">{row.location}</TableCell>
                  <TableCell align="left">
                    <IconButton onClick={() => window.open(row.link, "_blank")}>
                      <ImageIcon style={{ color: '#666E7D' }} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">


                    <IconButton onClick={() => deleteUniversity(row.id)}>
                      <DeleteIcon  onClick={() => deleteUniversity(row.id)} fontSize="small" style={{ color: "#666E7D" }} />
                    </IconButton>
                    <IconButton onClick={() => updateUniversity(row.id, row.name, row.link, row.country, row.location, row.countryId)}>
                      <EditIcon fontSize="small" style={{ color: '#666E7D' }} />
                    </IconButton>


                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Container>

  );
}

export default AddUniversity;