import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "../visaenquiry/UploadImages.css";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
    Breadcrumbs,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    LinearProgress,
} from "@mui/material";

import Button from "@mui/material/Button";
import styles from "../visaenquiry/VisaEnquiry.module.css";
import Steppers from "../visaenquiry/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { saveUploadImages, saveVisaDetail, saveUserInfo } from "../redux/action/Action";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import AWS from "aws-sdk";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const AddDocument = () => {
    const supportedFormats = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "text/plain",
        "application/pdf",
        "application/msword",
    ];
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const token = JSON.parse(localStorage.getItem("token"));

    const [showAlert, setShowAlert] = React.useState(false);
    const [dialogProps, setDailogProps] = useState({});
    const [cameraon, setCameraon] = useState(false);
    const [fileName, setFileName] = useState('');
    const [showProgress, setShowProgress] = useState(false);
    const [imagebyCamera, setImagebyCamera] = useState("");
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const [imageData, setImageData] = useState([]);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const backFunction = (e) => { navigate("/personalAdd"); };
    let temp = useSelector((state) => state.saveUserInfo);
    var userInfo = temp.userInfo;
    console.log(userInfo)
    var upload = userInfo; //useSelector((state) => state.saveUploadedImages);
    const webcamRef = React.useRef(null);
    const steps = [
        "Personal Details ",
        "Education & Work Experience",
        "Visa Detail & Finance",
        "Submit Application",
    ];

    const config = {
        accessKeyId: "AKIAREBXG45DTSWA434H",
        secretAccessKey: "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",
        region: "us-east-1",
        bucketName: "microdemand-dev"
    };

    const s3 = new AWS.S3(config);
    async function uploadImageToS3(iData) {
        const params = {
            Bucket: config.bucketName,
            Key: iData.name,
            Body: iData,
            ContentType: iData.type,
            ACL: "public-read",
        };
        return await s3.upload(params).promise();
    }

    const handleOnSubmit = (e) => {

        userInfo.images = imageData;
        console.log(userInfo)
        dispatch(saveUserInfo);
        navigate("/educationAdd");
    };
    // const onClickPhoto = React.useCallback(() => {
    const onClickPhoto = React.useCallback(() => {


        const imageSrc = webcamRef.current.getScreenshot();
        const blob = dataURItoBlob(imageSrc);
        const file = new File([blob], "Imagebycamera.jpeg", { type: "image/jpeg" });
        uploadImageToS3(file)
            .then((response) => {
                alert("Profile Image added Successfully");
                setImagebyCamera(response.Location);
                let d = {
                    imageUrl: response?.Location,
                    imageName: 'ProfilePic'
                }
                const objIndex = imageData.findIndex(obj => obj.imageName === 'ProfilePic');
                console.log(objIndex)
                if (objIndex === -1) {
                    console.log("setting data",d)
                    let newimg = [ ...imageData] ;
                    newimg.push(d)
                    console.log(newimg)
                    setImageData(newimg);

                } else {
                    const newimg = { ...imageData };
                    newimg[objIndex] = d;
                    setImageData(newimg);
                }
                console.log(imageData)

            })
            .catch((error) => console.log("uploadImageToS3 error", error));
        setCameraon(false);
        setOpen(false);
    }, [webcamRef, imageData]);

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: "image/jpeg" });
    }

    const handleCamera = () => {

        setCameraon(true);
        setOpen(true);
    };

    const handleInputChange = (event, index) => {
        const file = event.target.files[0];
        if (file && supportedFormats.includes(file.type)) {
            setDailogProps({
                index: index,
                file: file,
            });
            setShowAlert(true);
        } else {
            alert("Unsupported file format. Please select a supported file.");
        }
        setDailogProps({
            index: index,
            file: event.target.files[0],
        });
        setShowAlert(true);
    };

    const uploadonClick = async () => {
        if (fileName && dialogProps?.file && supportedFormats.includes(dialogProps.file.type)) {
            setShowProgress(true);
            const file = dialogProps.file;
            const index = dialogProps.index;

            try {
                const response = await uploadImageToS3(file);
                console.log("uploadImageToS3 Success", response);
                console.log("file name", file.name);
                const newImage = {
                    imageUrl: response?.Location,
                    imageName: fileName
                };

                setImageData(prevImageData => [...prevImageData, newImage]);

                setFileName('');
                setShowProgress(false);
                setShowAlert(false);
            } catch (error) {
                console.log("uploadImageToS3 error", error);
            }
        } else {
            alert('Please choose file name')
        }
    };

    const handleFileName = (e) => {
        setFileName(e.target.value);
    }

    const handleDeleteImage = (indexToDelete) => {
        const updatedImageData = imageData.filter((_, index) => index !== indexToDelete);
        const updatedUserInfo = { ...userInfo, images: updatedImageData };
        dispatch(saveUploadImages(updatedUserInfo));

        setImageData(updatedImageData);
    };

    useEffect(() => {
        console.log(userInfo)
        if (userInfo.images) {
            setImageData(userInfo.images);
        }
    }, [imageData]);


    return (
        <>
            <Container maxWidth="mx" className={styles.containerFix}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">

                    </DialogTitle>
                    <DialogContent>
                        <Grid container>

                            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <Webcam
                                    audio={false}
                                    height={220}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={220}
                                    borderRadius={14}
                                />

                            </Grid>


                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={onClickPhoto} autoFocus>
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen={fullScreen}
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure you want to upload image?"}
                    </DialogTitle>
                    <DialogContent>
                        {showProgress && (
                            <LinearProgress />
                        )}
                        <p>File Name:</p>
                        <TextField
                            type="text"
                            id="fname"
                            size="small"
                            name="imageName"
                            value={fileName}
                            onChange={(e) => handleFileName(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            variant="contained"
                            onClick={() => setShowAlert(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            autoFocus
                            variant="contained"
                            onClick={(e) => uploadonClick(e)}>
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
                <Container maxWidth="mx" className={styles.containerFix}>
                    <Grid item>
                        <Grid item sx={{ flexWrap: "wrap" }}>
                            <Box className={styles.breadcrumFix} >
                                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" href="/EnquiriesList">
                                        Home
                                    </Link>
                                    <Typography color="text.primary">Documents</Typography>
                                </Breadcrumbs>
                            </Box>
                            <Box sx={{ width: "100%", mt:2 }}>
                                <Steppers activeSteps={1}></Steppers>
                            </Box>
                        </Grid>
                        <Grid item sx={{ pb: 5 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    "& > :not(style)": {
                                        mt: 2,
                                        ml: 8,
                                        width: "-webkit-fill-available",
                                        mr: 8,
                                    },
                                }}
                            >
                                <Paper elevation={3}>
                                    <Grid item sx={{ pl: 10, pr: 10, flexWrap: "wrap", mt: 5 }}>

                                        <Grid container spacing={0}>
                                        </Grid>
                                        <Grid container direction="row"
                                            justifyContent={"center"}
                                            alignItems="center"
                                            marginBottom={2}
                                            marginTop={4} >
                                            <Grid item >

                                                <input
                                                    type="file"
                                                    id="fname"
                                                    name="imageUrl"
                                                    value=""
                                                    className="Input_box"
                                                    style={{ margin: '20px 0' }}
                                                    onChange={(e) => handleInputChange(e)}
                                                />

                                                <Grid container>
                                                    {imageData?.map((item, index) => (
                                                        <Grid item key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: "20px" }}>
                                                            <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px" }}>
                                                                {item?.imageUrl?.endsWith(".pdf") ? (
                                                                    <PictureAsPdfIcon style={{ fontSize: "60px", color: "red" }} />
                                                                ) : item?.imageUrl?.endsWith(".png") || item?.imageUrl?.endsWith(".jpeg") || item?.imageUrl?.endsWith(".jpg") ? (
                                                                    <ImageIcon style={{ fontSize: "60px", color: "red" }} />
                                                                ) : (
                                                                    <InsertDriveFileIcon style={{ fontSize: "60px", color: "red" }} />
                                                                )}
                                                                <CloseIcon
                                                                    onClick={() => handleDeleteImage(index)}
                                                                    style={{ cursor: "pointer", position: "absolute", top: "0", right: "0", fontSize: "20px", color: "black", fontWeight: "bold" }}
                                                                />
                                                                <p>{item?.imageName}</p> 
                                                            </div>
                                                        </Grid>
                                                    ))}
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={6}>



                                                < AddAPhotoIcon onClick={() => handleCamera()}></AddAPhotoIcon>


                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                p: 2,
                                                marginTop: 8
                                            }}
                                        >
                                            <Grid item xs={1}>
                                                <Button variant="outlined" onClick={backFunction}>
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button variant="outlined" onClick={handleOnSubmit}>
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </>
    );
};

export default AddDocument;
