import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./app/dashboard/dashboard";
import store from "./app/redux/store";
import User from "./app/user/user";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 


const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Lato ,sans-serif',
      textTransform: 'none',

    },
  },
});


root.render(
  // <React.StrictMode>

  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
    </ThemeProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


