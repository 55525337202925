import React, {useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./personal.css";
import "../appyApplication/index";
import {
    Link,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
    Paper,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import styles from "../visaenquiry/VisaEnquiry.module.css";

import Steppers from "../visaenquiry/Stepper";
import {saveUserInfo} from "../redux/action/Action";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import {VISA_URL} from "../../constant/constants";
import {useLocation} from 'react-router-dom';
import moment from "moment";
import {ApplyUniversity} from "../appyApplication/index";
import addUniversity from "../universities/addUniversity";

const PersonalAdd = () => {
    const [salutation, setSalutation] = React.useState("Salutation");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedDOB, setSelectedDOB] = useState(null);
    const childRef = useRef();
    let userDetail = useSelector((state) => state.savePersonalDetails);
    let data = userDetail.personalDetail;
    let temp = useSelector((state) => state.saveUserInfo);
    let userInfo = temp.userInfo;
    const phoneRegex =
        /^(\+1)?[-.\s]?\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
    const mobileRegex = /^(?:[6789]\d{9})?$/;
    const nameRegex = /^(?!.*\s{2,})[a-zA-Z\s]{0,50}$/;
    const pancardRegex = /^(?:[A-Z]{5}[0-9]{4}[A-Z])?$/;
    const passpostRegex = /^[A-Z][1-9]\d\s?\d{4}[1-9]$/;
    const emailRegex = /^(?:[^\s@]+@[^\s@]+\.[^\s@]+)?$/;
    const adharcardRegex = /^([0-9]){12}$/;
    const addressRegex = /^(?:(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s/]{0,100})?$/;
    const [error, setError] = useState({
        aadharNo: "",
        address: "",
        dob: "",
        email: "",
        altEmail: "",
        fatherName: "",
        gender: "",
        name: "",
        maritalStatus: "",
        mobileNo: "",
        altMobileNo: "",
        passportNo: "",
        panNo: "",
        salutation: "",
        visaType: "",
        country: "",
        university: ""
    });
    const [countryList, setCountryList] = useState();
    const [country, setCountry] = useState('');
    const [univ, setUniv] = useState('');
    const [universityList, setUniversityList] = useState();
    const [selectedUni, setSelectedUni] = useState([]);

    const [user, setUser] = useState({

        mobileNo: "",
        altMobileNo: "",
        name: "",
        fatherName: "",
        panNo: "",
        email: "",
        altEmail: "",
        passportNo: "",
        aadharNo: "",
        dob: " ",
        address: "",
        salutation: "",
        visaType: "",
        gender: "",
        maritalStatus: "",
        country: "",
        university: "",
        applyFor:""
    });
    const token = JSON.parse(localStorage.getItem("token"));

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        if (name == "Phone") {
            if (phoneRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    Phone: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    Phone: "Error",
                }));
            }
        }
        if (name == "mobileNo") {
            if (mobileRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: parseInt(value),
                }));
                setError((err) => ({
                    ...err,
                    mobileNo: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    mobileNo: "Error",
                }));
            }
        }

        if (name == "altMobileNo") {
            if (value === user.mobileNo) {
                setError((err) => ({
                    ...err,
                    altMobileNo: "Alternate mobile number cannot be the same as the mobile number.",
                }));
            } else if (mobileRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: parseInt(value),
                }));
                setError((err) => ({
                    ...err,
                    altMobileNo: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    altMobileNo: "Please enter a valid number",
                }));
            }
        }

        if (name == "name") {
            if (nameRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    name: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    name: "Error",
                }));
            }
        }
        if (name == "fatherName") {
            if (nameRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    fatherName: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    fatherName: "Error",
                }));
            }
        }
        if (name == "panNo") {
            if (pancardRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    panNo: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    panNo: "Error",
                }));
            }
        }
        if (name == "email") {
            if (emailRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    email: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    email: "Error",
                }));
            }
        }

        if (name == "altEmail") {
            if (emailRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    altEmail: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    altEmail: "Error",
                }));
            }
        }

        if (name == "passportNo") {
            if (passpostRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    passportNo: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    passportNo: "Error",
                }));
            }
        }
        if (name == "aadharNo") {
            if (adharcardRegex.test(value)) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                setError((err) => ({
                    ...err,
                    aadharNo: "",
                }));
            } else {
                setError((err) => ({
                    ...err,
                    aadharNo: "Error",
                }));
            }
        }
        if (name === "dob") {
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if (name === "country") {
            let countryName = countryList.find(o => o._id === value);
            setCountry(countryName.country);
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if (name === "country") {
            let countryName = countryList.find(o => o._id === value);
            setCountry(countryName.country);
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if (name === "university") {
            console.log(value)

            let univName = universityList?.find(o => o._id === value);
            setUniv(univName.name);
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (
            error.mobileNo === "Error" ||
            error.aadharNo === "Error" ||
            error.address === "Error" ||
            error.email === "Error" ||
            error.altEmail === "Error" ||
            error.fatherName === "Error" ||
            error.altMobileNo === "Error" ||
            error.name === "Error" ||
            error.panNo === "Error" ||
            error.passportNo === "Error" ||
            //user.aadharNo === "" ||
            user.address === "" ||
            //user.dob === "" ||
            user.email === "" ||
            //user.fatherName === undefined ||
            user.gender === "" ||
            user.maritalStatus === undefined ||
            user.mobileNo === "" ||
            // user.AltMobileNo === "" ||
            user.name === "" ||
            // user.panNo === undefined ||
            user.passportNo === "" ||
            //user.salutation === undefined ||
            user.visaType === ""
            // user.salutation=== undefined


        ) {
            alert("Please fill all the fields");
        } else {
            console.log(user)
            userInfo.salutation = user.salutation;
            userInfo.mobileNo = user.mobileNo;
            userInfo.altMobileNo = user.altMobileNo;
            userInfo.name = user.name;
            userInfo.fatherName = user.fatherName;
            userInfo.panNo = user.panNo;
            userInfo.email = user.email;
            userInfo.altEmail = user.altEmail;
            userInfo.passportNo = user.passportNo;
            userInfo.aadharNo = user.aadharNo;
            userInfo.dob = user.dob;
            userInfo.address = user.address;
            userInfo.salutation = user.salutation;
            userInfo.visaType = user.visaType;
            userInfo.gender = user.gender;
            userInfo.maritalStatus = user.maritalStatus;
            userInfo.country = user.country;
            userInfo.university = user.university;
            userInfo.visaTypeInfo = selectedUni;
            // userInfo.education=[{}];
            // userInfo.workExperience=[{}];
            // userInfo.testTaken={}
            dispatch(saveUserInfo(userInfo));
            navigate("/documentAdd");
        }
    };

    function update(target, src) {
        const res = {};
        Object.keys(target).forEach(k => res[k] = (src[k] ?? target[k]));
        console.log(res)
        return res;
    }

    function updateUniversity(data){

        setSelectedUni([...selectedUni, data]);
        console.log(selectedUni);
    }

    function removeApply(index){
        const tempArray = [...selectedUni];
        tempArray.splice(index, 1);
        setSelectedUni(tempArray);
    }

    const selectCountry = async () => {
        let userArray = [];
        const countryList = await axios.post(
            `${VISA_URL}/users/getCountries`,
            {
                country: "",
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        setCountryList(countryList?.data?.counrtries);

    };

    const selectUniversity = async () => {
        let userArray = [];
        const universityList = await axios.get(
            `${VISA_URL}/users/getUniversities`,

            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        setUniversityList(universityList?.data?.apiRes);
        console.log(universityList)

    };
    useEffect(() => {
        selectCountry();
        selectUniversity();
        if (!token) {
            navigate("/login");
        }

        if (userInfo) {
            console.log(userInfo)
            updatereduxData(userInfo);
        }

    }, []);

    function updatereduxData(data) {
        const date = new Date();

        var t = {
            mobileNo: data?.mobileNo || "",
            altMobileNo: data?.altMobileNo || "",
            name: data?.name || "",
            fatherName: data?.fatherName || "",
            panNo: data?.panNo || "",
            email: data?.email || "",
            altEmail: data?.altEmail || "",
            passportNo: data?.passportNo || "",
            aadharNo: data?.aadharNo || "",
            dob: data?.dob || moment(date).format("YYYY-MM-DD"),
            address: data?.address || "",
            salutation: data?.salutation || "",
            visaType: data?.visaType || "",
            gender: data?.gender || "",
            maritalStatus: data?.maritalStatus || "Unmarried",
            country: data?.country || "",
            university: data?.university || "",
        }
        setUser(t);
    }

    const [open, setOpen] = React.useState(false);

    const handleOnClickAway = () => {
        setOpen(false);
    };
    const isNumericKey = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    };
    return (
        <Container maxWidth="mx" sx={{padding: "12px"}}>
            <Grid item>
                <Box className={styles.breadcrumFix}>
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/EnquiriesList">
                            Home
                        </Link>
                        <Typography color="text.primary">Personal</Typography>
                    </Breadcrumbs>
                </Box>
                <Box sx={{width: "100%"}}>
                    <Steppers activeSteps={0}></Steppers>
                </Box>
                <Grid>
                    <Box
                        sx={{
                            display: "flex",
                            //   flexWrap: "wrap",
                            "& > :not(style)": {
                                mt: 2,
                                ml: 8,
                                mr: 8,
                                width: "-webkit-fill-available",
                                pb: 2,
                            },
                        }}
                    >
                        <Paper elevation={3}>

                            <Grid container spacing={1} pl={1} pr={1} mt={1}>

                                <Grid item xs={12} sm={4} p={2}>
                                    <FormControl
                                        sx={{textAlign: "left"}}
                                        size="small"
                                        fullWidth
                                        required

                                    >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Salutation
                                        </InputLabel>
                                        <Select

                                            shrink={true}
                                            labelId="demo-simple-select-helper-label"
                                            id="outlined-required"
                                            label="Salutation"
                                            name="salutation"
                                            fullWidth
                                            defaultValue=""
                                            value={user?.salutation}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value={"Mr"}>Mr</MenuItem>
                                            <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                            <MenuItem value={"Miss"}>Miss</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* </TextField> */}
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        size="small"
                                        required
                                        InputLabelProps={{shrink: true}}
                                        fullWidth
                                        id="outlined-required"
                                        label="Name"
                                        name="name"
                                        value={user?.name}
                                        onChange={handleInputChange}
                                        error={error.name}
                                        helperText={
                                            error.name
                                                ? "Please enter a valid name."
                                                : ""
                                        }
                                    />

                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        id="outlined-required"
                                        fullWidth
                                        label="Father Name"
                                        name="fatherName"
                                        value={user?.fatherName}
                                        onChange={handleInputChange}
                                        error={error.fatherName}
                                        helperText={
                                            error.fatherName
                                                ? "Please enter a valid father name."
                                                : ""
                                        }
                                    />

                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        required
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        id="outlined-required"
                                        label="Address"
                                        fullWidth
                                        name="address"
                                        value={user?.address || "" || data?.Address}
                                        onChange={handleInputChange}
                                        error={error.address}
                                        helperText={
                                            error.address
                                                ? "Please enter a valid address."
                                                : ""
                                        }
                                    />

                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        required
                                        InputLabelProps={{shrink: true}}
                                        fullWidth
                                        size="small"
                                        id="outlined-required"
                                        label="Mobile Number"
                                        name="mobileNo"
                                        value={user?.mobileNo}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => !isNumericKey(e) && e.preventDefault()} // Prevent non-numeric keys
                                        error={error.mobileNo}
                                        helperText={
                                            error.mobileNo
                                                ? "Please enter a valid mobile number."
                                                : ""
                                        }
                                        inputProps={{
                                            inputMode: "numeric",
                                            maxLength: 10
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        InputLabelProps={{shrink: true}}
                                        fullWidth
                                        size="small"
                                        id="outlined-required"
                                        label=" Alternate Mobile Number"
                                        name="altMobileNo"
                                        value={user?.altMobileNo}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => !isNumericKey(e) && e.preventDefault()} // Prevent non-numeric keys
                                        error={error.altMobileNo}
                                        helperText={
                                            error.altMobileNo ? error.altMobileNo : ""
                                        }
                                        inputProps={{
                                            inputMode: "numeric",
                                            maxLength: 10
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        required
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        fullWidth
                                        id="outlined-required"
                                        label="Email"
                                        name="email"
                                        value={user?.email || data?.Email}
                                        onChange={handleInputChange}
                                        error={error.email}
                                        helperText={
                                            error.email
                                                ? "Please enter a valid email."
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        fullWidth
                                        id="outlined-required"
                                        label="Alternate Email"
                                        name="altEmail"
                                        value={user?.altEmail || data?.altEmail}
                                        onChange={handleInputChange}
                                        error={error.altEmail}
                                        helperText={
                                            error.altEmail
                                                ? "Please enter a valid email."
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        id="outlined-required"
                                        label="Aadhar"
                                        name="aadharNo"
                                        fullWidth
                                        value={user?.aadharNo}
                                        onChange={handleInputChange}
                                        error={error.aadharNo}
                                        helperText={
                                            error.aadharNo
                                                ? "Please provide a valid Aadhar Number"
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        required
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        fullWidth
                                        id="outlined-required"
                                        label="Passport"
                                        name="passportNo"
                                        value={user?.passportNo}
                                        onChange={handleInputChange}
                                        error={error.passportNo}
                                        helperText={
                                            error.passportNo
                                                ? "Please enter a valid passport number."
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <TextField
                                        InputLabelProps={{shrink: true}}
                                        size="small"
                                        fullWidth
                                        id="outlined-required"
                                        label="Pancard"
                                        name="panNo"
                                        value={user?.panNo}
                                        onChange={handleInputChange}
                                        error={error.panNo}
                                        helperText={
                                            error.panNo
                                                ? "Please enter a valid Pan number."
                                                : ""
                                        }
                                    />

                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>
                                    <div className="custom-datepicker">
                                        <DatePicker
                                            selected={selectedDOB}
                                            onChange={(date) => setSelectedDOB(date)}
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            showMonthDropdown
                                            open={open}
                                            onClickOutside={() => setOpen(false)}
                                            onFocus={() => setOpen(true)}
                                            customInput={
                                                <TextField
                                                    label="DOB"
                                                    type="date"
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    size="small"
                                                    name="dob"
                                                    value={moment(user?.dob).format("YYYY-MM-DD")}
                                                    onChange={handleInputChange}
                                                    error={error.dob}
                                                    helperText={
                                                        error.dob
                                                            ? "Please enter a valid DOB."
                                                            : ""
                                                    }
                                                    onClick={() => setOpen(true)}
                                                    onFocus={() => setOpen(true)}
                                                />
                                            }
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4} p={2}>

                                    <FormControl
                                        sx={{textAlign: "left"}}
                                        size="small"
                                        fullWidth
                                        required
                                    >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Gender
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="outlined-required"
                                            // value={'VisaType'}
                                            label="Gender"
                                            defaultValue={user?.gender}
                                            // onChange={handleChange}
                                            name="gender"
                                            value={user?.gender}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value={"Male"}>Male</MenuItem>
                                            <MenuItem value={"Female"}>Female</MenuItem>
                                            <MenuItem value={"Other"}>Other</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>

                                    <FormControl
                                        sx={{textAlign: "left"}}
                                        size="small"
                                        fullWidth
                                        required
                                    >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Marital Status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="outlined-required"
                                            label="Marital Status"
                                            name="maritalStatus"
                                            defaultValue=""
                                            value={user?.maritalStatus}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value={"Married"}>Married</MenuItem>
                                            <MenuItem value={"Unmarried"}>Unmarried</MenuItem>
                                        </Select>
                                    </FormControl>


                                </Grid>
                                <Grid item xs={12} sm={4} p={2}>
                                    <FormControl
                                        sx={{textAlign: "left"}}
                                        size="small"
                                        fullWidth
                                        required
                                    >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Visa Type
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-helper-label"
                                            id="outlined-required"
                                            label="Salutation"
                                            name="visaType"
                                            defaultValue=""
                                            value={user?.visaType}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value={"Tourist"}>Tourist</MenuItem>
                                            <MenuItem value={"Study"}>Study</MenuItem>
                                            <MenuItem value={"Work"}>Work</MenuItem>
                                            <MenuItem value={"Business"}>Business</MenuItem>
                                            <MenuItem value={"PR"}>PR(Permanent Resident)</MenuItem>
                                            <MenuItem value={"Onshore"}>OnShore</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item item xs={12} sm={12} p={2}>
                                    <ApplyUniversity updateUniversity={updateUniversity} removeApply={removeApply}/>
                                </Grid>

                                <Grid item xs={12} sm={12} p={2}>
                                    <Grid container spacing={1} sx={{justifyContent: "center"}}>
                                        <Grid item xs={12} sm={6} p={1}>
                                            <Button variant="outlined" onClick={handleOnSubmit}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );

};

export default PersonalAdd;