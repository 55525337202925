import React, { useEffect, useState, useRef } from "react";
import "./EnquiryDetails.css";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Breadcrumbs,
  Link,
  Typography,
  Container,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import AlignItemsList from "./Comments.js";
import axios from "axios";
import { VISA_URL } from "../../../constant/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CircularJSON from "circular-json";
import { Circles } from 'react-loader-spinner';
import Person2Icon from '@mui/icons-material/Person2';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import QuizIcon from '@mui/icons-material/Quiz';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Viewenquirylist = () => {
  const theme = useTheme();
  const childRef = useRef();
  const [currentChannelData, setCurrentChannelData] = useState([]);
  const [comissionReceivedStatus, setComissionReceivedStatus] = useState('');
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [studentId, setStudentId] = useState('');

  const handleCheckboxClick = (value) => {
    setSelectedCheckbox(value);
    setShowConfirmation(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setSelectedCheckbox(null);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [previewData, setPreviewData] = useState();
  const [newChannelValue, setNewChannelValue] = useState('');
  const [newChannelName, setNewChannelName] = useState('');
  const [education, setEducation] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [testTaken, setTestTaken] = useState([]);
  const [comment, setComment] = useState("");
  const [channelsData, setChannelsData] = useState("");
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState("");
  const [editable, setEditable] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [addChannelDialog, showAddChannelDialog] = useState(false);
  const [userList, setUserList] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));
  const [visaApprovedDialog, showVisaApprovedDialog] = useState(false);
  const [visaApprovalDate, setVisaApprovalDate] = useState();
  const [visaApprovalStudentId, setVisaApprovalStudentId] = React.useState(null);
  const [intakeValue, setIntakeValue] = useState(null);
  const [channelValue, setChannelValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [channelList, setChannelList] = React.useState([]);
  const [student, setStudent] = React.useState('');
  const [openStatus, setOpenStatus] = React.useState(false)
  const [selectedStatus, setSelectedStatus] = React.useState('');
  const [openApply, setOpenApply] = React.useState(false)
  const [universityList, setUniversityList] = React.useState([]);
  const [university, setUniversity] = React.useState([]);
  const [appIntake, setAppIntake] = React.useState('');
  const [applications, setApplications] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState('');
  const [showUniversity, setShowUniversity] = React.useState(false);
  const [docOpen, setDocOpen] = React.useState(false);
  const [viewDocUrl, setViewDocUrl] = React.useState(false);
  const [viewDocName, setViewDocName] = React.useState('');
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState('');
  const [idToDelete, setIdToDelete] = React.useState('');
  const [openChqngeStatus, setOpenChangeStatus] = React.useState(false);
  const [statusForUpdate, setStatusForUpdate] = React.useState(false);
  const [idToUpdate, setIdToUpdate] = React.useState('');
  const [countryName, setCountryName] = React.useState('');

//   var perserveSearch = useSelector((store) => store.perserveSearch.status);
// console.log(perserveSearch)


  const closeChangeStatus = () => {
    setOpenChangeStatus(false)
  }

  const handleApplicationStatus = (e) => {
    const { name, value } = e.target;
    setStatusForUpdate(value);


  }
  const handleUpdateStatus = async () => {
    const result = await axios.put(
      `${VISA_URL}/users/updateApplication`,
      CircularJSON.stringify({
        id: idToUpdate,
        status: statusForUpdate,

      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (result?.data?.response == "success") {
      studentsData(location.state.studentId);
    }
    setOpenApply(false);
    setOpenChangeStatus(false)
  }

  const openChangeStatus = (id) => {
    setIdToUpdate(id)
    setOpenChangeStatus(true)

  }

  const handleDocClickOpen = (url, name) => {
    setViewDocUrl(url)
    setViewDocName(name)
    setDocOpen(true);
  };

  const handleDocClose = () => {
    setDocOpen(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };



  const handleUniversityChange = (event) => {
    //setOpenApply(false);
    setUniversity(event.target.value);
  }

  const handleDeleteConfirm = () => {
    setOpenConfirmDelete(false);
    onApplicationDelet()


  }

  const handleDeleteClose = () => {

  };

  const handleApplicationIntake = (e) => {
    setAppIntake(e.target.value)
  }
  const handleApplyClose = () => {
    setOpenApply(false);

  }

  const onApplicationDelet = async (id) => {
    const result = await axios.delete(
      `${VISA_URL}/users/deleteApplications?id=` + idToDelete,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (result?.data?.response == "success") {
      console.log(result?.data);

    }
    setOpenConfirmDelete(false);
    studentsData(location.state.studentId);


  }

  const onApply = async () => {
    const result = await axios.post(
      `${VISA_URL}/users/addApplication`,
      CircularJSON.stringify({
        countryId: country,
        universityId: university,
        studentId: location.state.studentId,
        intake: appIntake,
        // countryName:countryName

      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (result?.data?.response == "success") {
      studentsData(location.state.studentId);
    } else {
      alert("University already exists for this student.")
    }
    setOpenApply(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStatusClickOpen = () => {
    setOpenStatus(true);
  };

  const handleStatusClose = () => {
    setOpenStatus(false);
  };

  const studentsData = async (studentId) => {
    const result = await axios.get(
      `${VISA_URL}/users/getStudent?studentId=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (result?.data) {
      setLoading(false);
      setPreviewData(result?.data?.data);
      setStudent(result?.data?.data?.name)
      let tempChannelsdata = "";
      result?.data?.data?.channel.map((items) => {
        tempChannelsdata = tempChannelsdata + items + "  ,"
      })

      setChannelsData(tempChannelsdata);
      setWorkExperience(result?.data?.data?.workExperience);
      setEducation(result?.data?.data?.education);
      setApplications(result?.data?.data?.visaTypeInfo);
      setTestTaken(result?.data?.data?.testTaken);
      setSelectedCheckbox(result?.data?.data?.comissionReceivedStatus)
      console.log(result?.data?.data?.comments);
      if (result?.data?.data?.comments) {
        childRef.current.showComments(result?.data?.data?.comments);
      }
    }

    setStatus(result?.data?.data?.applicationStatus);
    setAssignedTo(result?.data?.data?.assignee?._id)
    console.log(status);
  };

  const intakeChangeHandler = (e) => {
    setIntakeValue(e.target.value);
  };

  const submitVisaApprovedDialog = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(
        `${VISA_URL}/users/addApprovedChannel`,
        {
          intakeDate: visaApprovalDate,
          intake: intakeValue,
          approvedChannel: channelValue,
          studentId: visaApprovalStudentId,
        },
        config
      )
      .then((response) => {
        updateStatus('visaApproved')
        setLoading(false);
        console.log("Res ==> ", response);
        showVisaApprovedDialog(false);
        // fetchData();
      })
      .catch((error) => {
        console.log("Error ==> ", error);
      });
  };

  const getUsersListData = async () => {
    const result = await axios.get(`${VISA_URL}/users/getUsers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });

    setLoading(false);
    setUserList(result);
  }

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    getUsersListData();
    if (location.state.studentId) {
      studentsData(location.state.studentId);
    }
    getChannelList();
    selectCountry()

  }, []);

  const assignedToOnClick = async (event) => {
    const result = await axios.put(
      `${VISA_URL}/users/assignUser`,
      { userId: event.target.value, studentId: location.state.studentId },
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("result assignUser", result);
    setLoading(false);
    setAssignedTo(event.target.value)

  }

  const onBack = () => {
    navigate("/EnquiriesList");
  };

  const edit = () => {
    console.log("edit");
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const addComents = async (studentId) => {
    const result = await axios.post(
      `${VISA_URL}/users/addComments?studentId=` + location.state.studentId,
      CircularJSON.stringify({
        text: comment,
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (result?.data?.response == "success") {
      console.log(result?.data);
      studentsData(location.state.studentId);
      setLoading(false);
    }
    setOpen(false);
  };

  const addComentsDuplicate = async (studentId, value) => {
    const result = await axios.post(
      `${VISA_URL}/users/addComments?studentId=` + location.state.studentId,
      CircularJSON.stringify({
        text: "Changed status to " + value,
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).catch(function (error) {
      setOpen(false);
    })
    if (result?.data?.response == "success") {
      console.log(result?.data);
      studentsData(location.state.studentId);
      setLoading(false);
    }
    setOpen(false);
  };


  const getChannelListForStudent = async (stuId) => {
    const result = await axios.get(
      `${VISA_URL}/users/getChannel?studentId=${stuId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setLoading(false);
    setCurrentChannelData(result?.data?.channels);
  };


  const getChannelList = async (stuId) => {
    const result = await axios.get(
      `${VISA_URL}/users/getChannelList`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setLoading(false);
    console.log(result?.data?.channels);
    setChannelList(result?.data?.channels);
    channelList?.map((items) => {
      console.log(items._id)
    })
  };


  const onChannelChanhge = async (event) => {
    const { name, value } = event.target;
    setNewChannelValue(value)
    setNewChannelName(name);
  }

  const selectUniversity = async (value) => {
    //setShowUniversity(false)
    let userArray = [];
    const universityList = await axios.get(
      `${VISA_URL}/users/getCountryUniversities?countryId=` + value,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(universityList?.data?.universityList)
    if (universityList?.data?.universityList) {
      setUniversityList(universityList?.data?.universityList);
      setShowUniversity(true)
      // console.log(universityList?.data)
    } else {
      setShowUniversity(false)
      alert("No University found for this country. ")
    }
  };
  const getApplications = async (value) => {
    let userArray = [];
    const applicationsList = await axios.get(
      `${VISA_URL}/users/getApplications?studentId=${location.state.studentId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (applicationsList.data.applications.data) {
      console.log(applicationsList.data.applications.data)

      setApplications(applicationsList?.data.applications?.data)
      console.log(applications)
    } else {
      //setShowUniversity(false)
      alert("No University found for this country. ")
    }


  };

  const updateStatus = (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(
        `${VISA_URL}/users/addStudentApplicationStatus?studentId=${location.state.studentId}`,
        {
          // studentId: Id,
          status: value,
          // status: selectedOption,
        },
        config
      )
      .then((response) => {
        setLoading(false);
        setComment(value)
        addComentsDuplicate(location.state.studentId, value);

      })
      .catch((error) => {
        console.log("Error ==> ", error);
      });

  }

  const onStatusConfirm = async (data) => {

    setSelectedStatus(data.target.value);
    setOpenStatus(true);
    // onStatus(data)
  }

  const onDelete = async (id) => {


    setIdToDelete(id);
    setOpenConfirmDelete(true)
  };

  const updateValueOnServer = async () => {
    try {
      
      const response = await axios.post(`${VISA_URL}/users/updateComissionReceivedStatus`,
       {
        comissionReceivedStatus: selectedCheckbox,
        studentId: location.state.studentId,
      },
      {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      }
      );
        alert(response?.data?.message);
      console.log('API response:', response.data);
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const handleConfirm = async () => {
    console.log(`Updating value for ${selectedCheckbox}`);
    updateValueOnServer();
    setShowConfirmation(false);
  };

  const onStatus = async () => {

    if (selectedStatus == "visaApproved") {
      showVisaApprovedDialog(true);
      setVisaApprovalStudentId(location.state.studentId);
      getChannelListForStudent(location.state.studentId);
    } else {
      updateStatus(selectedStatus);
      setStatus(selectedStatus);
    }
    setOpenStatus(false)

  };

  const addChannelHandler = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        `${VISA_URL}/users/assignStudentChannel`,
        {
          channel: newChannelName, studentId: location.state.studentId, channelId: newChannelValue
        },
        config
      )
      .then((response) => {
        setLoading(false);
        console.log("Res ==> ", response);
        showAddChannelDialog(false);
        if (response?.data?.response == "success") {
          //setNewChannelValue("");
          studentsData(location.state.studentId);
        }
      })
      .catch((error) => {
        console.log("Error ==> ", error);
      });
  };

  const getComents = async (studentId) => {
    const result = await axios.get(
      `${VISA_URL}/users/getComments?studentId=${studentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (result?.data?.comments) {
      setLoading(false);
      childRef.current.showComments(result?.data?.comments);
    }
    //getComents(location.state.studentId);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setComment(value);
  };

  const selectCountry = async () => {

    const countryList = await axios.post(
      `${VISA_URL}/users/getCountries`,
      {
        country: "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(countryList?.data?.counrtries);
    setCountryList(countryList?.data?.counrtries);

  };

  const handleCountryChange = (event) => {
    console.log(event)
    //let index = event.nativeEvent.target.selectedIndex;
    //let label = event.nativeEvent.target[index].text;
    const { name, value } = event.target;
    setCountry(value)
    selectUniversity(value);
    //setCountryName(label)


  }

  if (loading) {
    return (
      <div className="loader-container">
        <Circles type="Puff" color="#3081E8" height={100} width={100} />
      </div>
    );
  }


  return (
    <>
      <Container>
        <Dialog
          fullScreen={fullScreen}
          open={addChannelDialog}
          onClose={() => showAddChannelDialog(false)}

        >
          <DialogTitle id="responsive-dialog-title">
            {"Please select the channel name:"}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 220 }}>
                  <InputLabel id="demo-simple-select-helper-label">Channel</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={newChannelValue}
                    fullWidth
                    onChange={(e) => onChannelChanhge(e)}
                  >  {channelList?.map((items) => {
                    return (
                      <MenuItem value={items?._id}>{items?.channel}</MenuItem>
                    );
                  })}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus

              onClick={() => addChannelHandler()}
              size="small"
            >
              Submit
            </Button>
            <Button
              autoFocus

              onClick={() => showAddChannelDialog(false)}
              size="small"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          maxWidth="lg"
          direction="column"
          justify="flex-end"
          spacing={4}
        >
          <Grid item m={1}>
            <Box>
              <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/EnquiriesList">
                  Home
                </Link>
                <Typography color="text.primary">Countries</Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
        </Grid>


        <Accordion expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"

          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 15 }}><Person2Icon></Person2Icon> Personal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container maxWidth="lg">
              <Grid container maxWidth="lg" spacing={1}>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Name :  <Typography sx={{ color: '#666E7D' }}> {previewData?.name}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Date of Birth :<Typography sx={{ color: '#666E7D' }}>  {moment(previewData?.dob).format('DD-MM-YYYY')}</Typography>
                  </Typography>

                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Father Name : <Typography sx={{ color: '#666E7D' }}> {previewData?.fatherName}</Typography>
                  </Typography>

                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Aadhar No. : <Typography sx={{ color: '#666E7D' }}>  {previewData?.aadharNo}</Typography>
                  </Typography>

                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Pan No. : <Typography sx={{ color: '#666E7D' }}> {previewData?.panNo}</Typography>
                  </Typography>

                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Passport No. : <Typography sx={{ color: '#666E7D' }}> {previewData?.passportNo}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Email : <Typography sx={{ color: '#666E7D' }}> {previewData?.email}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Mobile No. : <Typography sx={{ color: '#666E7D' }}> {previewData?.mobileNo}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Alternate No. : <Typography sx={{ color: '#666E7D' }}> {previewData?.altMobileNo}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Visa Type : <Typography sx={{ color: '#666E7D' }}>  {previewData?.visaType}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Marital Status : <Typography sx={{ color: '#666E7D' }}> {previewData?.maritalStatus}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" minWidth={200} >
                    <Box sx={{ minWidth: 70 }} >Address :</Box> <Typography sx={{ color: '#666E7D' }}> {previewData?.address}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Refernce : <Typography sx={{ color: '#666E7D' }}> {previewData?.reference}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Channel: <Typography sx={{ color: '#666E7D' }}> {previewData?.channel}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Approved Channel : <Typography sx={{ color: '#666E7D' }}> {previewData?.approvedChannel}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Funds as Liqidity : <Typography sx={{ color: '#666E7D' }}> {previewData?.fundsAsLiquidAmt}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Funds as Property : <Typography sx={{ color: '#666E7D' }}> {previewData?.fundsAsProperty}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Funds as Anual Income : <Typography sx={{ color: '#666E7D' }}> {previewData?.fundsAsAnnualIncome}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Education Loan Required : <Typography sx={{ color: '#666E7D' }}> {previewData?.needEducationLoan ? "Yes" : "No"}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Education Loan Amount  : <Typography sx={{ color: '#666E7D' }}> {previewData?.fundsAsAnnualIncome}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Earlier Visa Refusal  :<Typography sx={{ color: '#666E7D' }}>  {previewData?.visaEarlierRefused ? "Yes" : "No"}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Visa Refusal reason  :<Typography sx={{ color: '#666E7D' }}>  {previewData?.visaRefusalReason}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Relative Abroad  : <Typography sx={{ color: '#666E7D' }}> {previewData?.relativesAbroad ? "Yes" : "No"}</Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Reason for sponser  : <Typography sx={{ color: '#666E7D' }}> {previewData?.relativeSponsorReason}</Typography>
                  </Typography>
                </Grid>




              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 16 }}><MenuBookIcon></MenuBookIcon> Education</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {education?.map((item) => (
              <Grid container maxWidth="lg">
                <Grid container maxWidth="lg" spacing={1}>
                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Qualification  : <Typography sx={{ color: '#666E7D' }}> {item?.qualification}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Institute/University : <Typography sx={{ color: '#666E7D' }}> {item?.institutionName}</Typography>
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Year : <Typography sx={{ color: '#666E7D' }}> {item?.year}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Board : <Typography sx={{ color: '#666E7D' }}> {item?.board}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Regular/Distance : <Typography sx={{ color: '#666E7D' }}> {item?.regular ? "Regular" : "Distance"}</Typography>
                    </Typography>
                  </Grid>

                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 16 }}><NetworkCheckIcon></NetworkCheckIcon> Work Experince</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {workExperience?.map((item) => (
              <Grid container maxWidth="lg">
                <Grid container maxWidth="lg" spacing={1}>
                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Years : <Typography sx={{ color: '#666E7D' }}> {item?.years}</Typography>
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Months : <Typography sx={{ color: '#666E7D' }}> {item?.months}</Typography>
                    </Typography>
                  </Grid>



                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Designation : <Typography sx={{ color: '#666E7D' }}> {item?.designation}</Typography>
                    </Typography>
                  </Grid>



                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Industry Type : <Typography sx={{ color: '#666E7D' }}> {item?.industryType}</Typography>
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography display="flex" justifyContent="flex-start" >
                      Job Type : <Typography sx={{ color: '#666E7D' }}> {item?.workSchedule}</Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 16 }}><QuizIcon></QuizIcon> Test Taken</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container maxWidth="lg">
              <Grid container maxWidth="lg" spacing={1}>
                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Test : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.testName ? previewData?.testTaken?.testName : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Reading : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.readScore ? previewData?.testTaken?.readScore : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Writing : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.writeScore ? previewData?.testTaken?.writeScore : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Speaking : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.speakingScore ? previewData?.testTaken?.speakingScore : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Listening : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.listeningScore ? previewData?.testTaken?.listeningScore : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    OverAll Score : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.overallScore ? previewData?.testTaken?.overallScore : "NA"}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Field of Study : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.fieldOfStudy}</Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography display="flex" justifyContent="flex-start" >
                    Institute of interest : : <Typography sx={{ color: '#666E7D' }}> {previewData?.testTaken?.instituteOfInterest}</Typography>
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 16 }}><LocalAirportIcon></LocalAirportIcon> Visa</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container
              direction="column"
              justifyContent="flex-end"
            >
              <Grid item>
                <Grid direction="row"
                  justifyContent="flex-end"
                  alignItems="self-end">
                  <Box display="flex" justifyContent="flex-end">
                    <Button size="small" onClick={() => { setOpenApply(true) }}><Typography sx={{ fontSize: 16 }}>+ Add</Typography> </Button>
                  </Box>

                </Grid>
              </Grid>
              <Grid item>
                <TableContainer

                >
                  <Table aria-label="simple table">
                    <TableHead
                      sx={{
                        "& th": {
                          color: "#666E7D",
                          backgroundColor: "#F8F9FA",
                          fontWeight: 'bold',
                          fontSize: 14
                        }
                      }}>
                      <TableCell align="left">University</TableCell>
                      <TableCell align="right">Country</TableCell>
                      <TableCell align="right">intake</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="left">Actions</TableCell>

                    </TableHead>
                    {applications.map((value) => (
                      <TableRow>
                        <TableCell>{value?.universityName}</TableCell>
                        <TableCell align="right">{value?.countryName}</TableCell>
                        <TableCell align="right">{value?.intake}</TableCell>

                        {(value.status == 'APPLIED') && (
                          <TableCell align="right">{'Applied'}</TableCell>
                        )}

                        {(value.status == 'RECEIVED') && (
                          <TableCell align="right">{'Offer Received'}</TableCell>
                        )}
                        {(value.status == 'SOP') && (
                          <TableCell align="right">{'SOP Given'}</TableCell>
                        )}
                        {(value.status == 'INTERVIEW') && (
                          <TableCell align="right">{'Interview Done'}</TableCell>
                        )}


                        <TableCell align="right">
                          <Grid sx={{ width: "max-content" }}>
                            <EditIcon
                              onClick={() => openChangeStatus(value._id)}
                              sx={{ color: "#6681CD", cursor: "pointer" }}
                            />
                            <DeleteIcon onClick={() => onDelete(value._id)} sx={{ color: "#6681CD", ml: 0, cursor: "pointer" }}
                            />

                          </Grid>

                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>

                </TableContainer>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="body2" style={{ color: "#6681CD", fontWeight: "bold", fontSize: 16 }}><ContentPasteSearchIcon></ContentPasteSearchIcon> Documents</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid container direction="column" justifyContent="flex-end">

              <Grid item>
                <TableContainer >
                  <Table aria-label="simple table">
                    <TableHead
                      sx={{
                        "& th": {
                          backgroundColor: "#F8F9FA",
                          color: "#666E7D",
                          fontWeight: 'bold',
                          fontSize: 14
                        }
                      }}

                    >
                      <TableCell align="left">Document Name</TableCell>
                      <TableCell align="center">Actions</TableCell>

                    </TableHead>
                    {previewData?.images.map((item) => (
                      <TableRow>
                        <TableCell align="left">{item.imageName}</TableCell>
                        <TableCell align="center">

                          <VisibilityIcon
                            sx={{ color: "green", cursor: "pointer" }}
                            onClick={() => handleDocClickOpen(item.imageUrl, item.imageName)}
                          />


                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>

                </TableContainer>
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>

        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
          >
            <Grid item>
              <Button onClick={showAddChannelDialog}>
                <AddIcon sx={{ fontSize: 18 }} />
                Add Channel{" "}
              </Button>
            </Grid>

            <Grid item marginTop={2}>
              <Typography>Commission Received ?</Typography>

              <Grid sx={{ display: 'flex' }}>
                <Grid
                  sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  onClick={() => handleCheckboxClick(true)}
                >
                  <Checkbox
                    {...label}
                    name="yes"
                    checked={selectedCheckbox === true}
                  />
                  <p style={{ marginLeft: 2 }}>Yes</p>
                </Grid>
                <Grid
                  sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  onClick={() => handleCheckboxClick(false)}
                >
                  <Checkbox
                    {...label}
                    name="no"
                    checked={selectedCheckbox === false}
                  />
                  <p style={{ marginLeft: 2 }}>No</p>
                </Grid>
              </Grid>

              {/* Confirmation Dialog */}
              <Dialog
                open={showConfirmation}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to update the value?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm} color="primary" autoFocus>
                    Update
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid item>
              <FormControl
                sx={{ textAlign: "left" }}
                size="small"
                fullWidth
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  sx={{ fontSize: 13 }}
                >
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="outlined-required"
                  label="Status"
                  name="status"
                  fullWidth
                  defaultValue={status}
                  value={status}
                  onChange={(value) => onStatusConfirm(value)}
                >
                  <MenuItem value={"incompleteDocuments"}>
                    Incomplete documents
                  </MenuItem>
                  <MenuItem value={"applicationOnAssessment"}>
                    Application on assessment
                  </MenuItem>
                  <MenuItem value={"offerApplied"}>Offer Applied</MenuItem>
                  <MenuItem value={"offerReceived"}>
                    Offer Received
                  </MenuItem>
                  <MenuItem value={"fileLogged"}>File Logged</MenuItem>
                  <MenuItem value={"visaApproved"}>Visa Approved</MenuItem>
                  <MenuItem value={"visaDecline"}>Visa Decline</MenuItem>
                  <MenuItem value={"onshore"}>Onshore</MenuItem>
                  <MenuItem value={"refund"}>Refund</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                sx={{ textAlign: "left" }}
                size="small"
                fullWidth
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  sx={{ fontSize: 13 }}
                >
                  Assigned
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="outlined-required"
                  label="Assigned"
                  name="Assigned"
                  fullWidth
                  defaultValue=""
                  value={assignedTo}
                  onChange={(value) => assignedToOnClick(value)}
                >
                  {userList?.data?.data?.users.map((items) => {

                    return (
                      <MenuItem value={items?._id}>{items?.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button onClick={handleClickOpen}>
                <AddIcon sx={{ fontSize: 18 }} />
                Comments{" "}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <AlignItemsList ref={childRef}></AlignItemsList>
      </Container>
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Enter Comments."}
        </DialogTitle>
        <DialogContent sx={{ minWidth: 350 }}>
          <TextField
            name="comment"
            fullWidth
            id="fullWidth"
            onChange={(event) => {
              handleInputChange(event);
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button

            onClick={() => addComents(location.state.studentId)}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={visaApprovedDialog}
        onClose={() => showVisaApprovedDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Please enter the following fields:"}
        </DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            label="Date"
            margin={"normal"}
            size={"small"}
            defaultValue={moment().format("YYYY-MM-DD")}
            value={visaApprovalDate}
            onChange={(event) =>
              setVisaApprovalDate(event.target.value)
            }
          />
          <FormControl
            sx={{ textAlign: "left" }}
            size="small"
            fullWidth
          >
            <InputLabel
              id="demo-simple-select-helper-label"
              sx={{ fontSize: 13 }}
            >
              Intake
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="outlined-required"
              label="Intake"
              name="intake"
              fullWidth
              value={intakeValue}
              onChange={(e) => intakeChangeHandler(e)}
            >
              <MenuItem value={"january"}>January</MenuItem>
              <MenuItem value={"february"}>February</MenuItem>
              <MenuItem value={"march"}>March</MenuItem>
              <MenuItem value={"april"}>April</MenuItem>
              <MenuItem value={"may"}>May</MenuItem>
              <MenuItem value={"june"}>June</MenuItem>
              <MenuItem value={"july"}>July</MenuItem>
              <MenuItem value={"august"}>August</MenuItem>
              <MenuItem value={"september"}>September</MenuItem>
              <MenuItem value={"october"}>October</MenuItem>
              <MenuItem value={"november"}>November</MenuItem>
              <MenuItem value={"december"}>December</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{ textAlign: "left" }}
            size="small"
            fullWidth
            style={{ marginTop: 8 }}
          >
            <InputLabel
              id="demo-simple-select-helper-label"
              sx={{ fontSize: 13 }}
            >
              Channel
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="outlined-required"
              label="Channel"
              name="channel"
              fullWidth
              value={channelValue}
              onChange={(e) => setChannelValue(e.target.value)}
            >
              {/* <MenuItem selected value={""}></MenuItem> */}
              {channelList?.map((item) => {
                return (
                  <MenuItem value={item.channel}>
                    {item.channel}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus

            onClick={() => submitVisaApprovedDialog()}
            size="small"
          >
            Submit
          </Button>
          <Button
            autoFocus

            onClick={() => showVisaApprovedDialog(false)}
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStatus}
        onClose={handleStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you realy wants to change the status ?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusClose}>Cancel</Button>
          <Button onClick={onStatus} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDelete}
        onClose={handleConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you realy wants to delete  the record ?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openApply}
        onClose={handleApplyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Apply for other university"}
        </DialogTitle>
        <DialogContent>
          <Grid item mt={2}>
            {countryList &&
              <FormControl
                sx={{ textAlign: "left" }}
                size="small"
                fullWidth
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  sx={{ fontSize: 16 }}
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="outlined-required"
                  label="Country"
                  name="country"
                  fullWidth
                  defaultValue=""
                  value={country}
                  onChange={handleCountryChange}

                >
                  {countryList?.map((items) => {
                    return <MenuItem value={items._id}>{items.country}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            }
          </Grid>
          <Grid item mt={2}>
            {showUniversity &&
              <FormControl
                sx={{ textAlign: "left" }}
                size="small"
                fullWidth
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  sx={{ fontSize: 16 }}
                >
                  University
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="outlined-required"
                  label="University"
                  name="university"
                  fullWidth
                  defaultValue=""
                  value={university}
                  onChange={handleUniversityChange}

                >
                  {universityList?.map((items) => {
                    return <MenuItem value={items._id}>{items.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            }
          </Grid>
          <Grid item mt={2}>

            <FormControl
              sx={{ textAlign: "left" }}
              size="small"
              fullWidth
            >
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{ fontSize: 13 }}
              >
                Intake
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="outlined-required"
                label="Intake"
                name="intake"
                fullWidth
                value={appIntake}
                onChange={(e) => handleApplicationIntake(e)}
              >
                <MenuItem value={"january"}>January</MenuItem>
                <MenuItem value={"february"}>February</MenuItem>
                <MenuItem value={"march"}>March</MenuItem>
                <MenuItem value={"april"}>April</MenuItem>
                <MenuItem value={"may"}>May</MenuItem>
                <MenuItem value={"june"}>June</MenuItem>
                <MenuItem value={"july"}>July</MenuItem>
                <MenuItem value={"august"}>August</MenuItem>
                <MenuItem value={"september"}>September</MenuItem>
                <MenuItem value={"october"}>October</MenuItem>
                <MenuItem value={"november"}>November</MenuItem>
                <MenuItem value={"december"}>December</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApplyClose}>Cancel</Button>
          <Button onClick={onApply} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={docOpen} onClose={handleDocClose}>
        <DialogTitle>{viewDocName}</DialogTitle>
        <DialogContent >
          <DialogContentText>

          </DialogContentText>
          <img src={viewDocUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocClose}>close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openChqngeStatus} onClose={closeChangeStatus}>
        <DialogTitle>{viewDocName}</DialogTitle>
        <DialogContent >
          <DialogContentText>

          </DialogContentText>
          <FormControl
            sx={{ textAlign: "left", minWidth: 300, marginTop: 2 }}
            size="small"
            fullWidth
          >
            <InputLabel
              id="demo-simple-select-helper-label"
              sx={{ fontSize: 13 }}
            >
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="outlined-required"

              label="Status"
              name="Status"
              fullWidth
              //value={appIntake}
              onChange={(e) => handleApplicationStatus(e)}
            >
              <MenuItem value={"APPLIED"}>Applied</MenuItem>
              <MenuItem value={"RECEIVED"}>Offer Received</MenuItem>
              <MenuItem value={"SOP"}>SOP Given</MenuItem>
              <MenuItem value={"INTERVIEW"}>Interview Done</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeChangeStatus}>close</Button>
          <Button onClick={handleUpdateStatus}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
